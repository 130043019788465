import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import HealthiergeLogo from '../assets/healthierge-logo-nonclaim-150.png';
import facebook from '../assets/s_facebook.png';
import instagram from '../assets/s_instagram.png';
import email from '../assets/s_email.png';
import whatsapp from '../assets/s_whatsapp.png';
import twitter from '../assets/s_twitter.png';
//import linkedin from './assets/s_linkedin.png';

const lList = [
    {
        id: 'GB',
        disclaimer: 'This website has been designed to provide information about medical services and health. All information on the site is obtained from reliable professional sources specializing in a particular medical field. Any copying and distribution of information from this website is not allowed in accordance with the privacy and data protection policy.',
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Medical Files', linkTo: '/medical-history' },
            { id: '4', text: 'Plastic Surgery Price List', linkTo: '/plastic-price-list' },
            { id: '5', text: 'Dentistry Price List', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ],
        freepik: 'Photo credits to freepik.com'
    },
    {
        id: 'DE',
        disclaimer: 'Diese Website wurde erstellt, um Informationen über medizinische Dienstleistungen und Gesundheit bereitzustellen. Alle Informationen auf der Website stammen aus zuverlässigen professionellen Quellen, die auf einen bestimmten medizinischen Bereich spezialisiert sind. Das Kopieren und Verteilen von Informationen von dieser Website ist gemäß den Datenschutzbestimmungen nicht gestattet.',
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Medical Files', linkTo: '/medical-history' },
            { id: '4', text: 'Plastic Surgery Price List', linkTo: '/plastic-price-list' },
            { id: '5', text: 'Dentistry Price List', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ],
        freepik: 'Bildnachweis an freepik.com'
    },
    {
        id: 'FR',
        disclaimer: "Ce site Web a été conçu pour fournir des informations sur les services médicaux et la santé. Toutes les informations présentes sur le site sont obtenues auprès de sources professionnelles fiables, spécialisées dans un domaine médical particulier. Toute copie et distribution d'informations de ce site Web n'est pas autorisée conformément à la politique de confidentialité et de protection des données.",
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Medical Files', linkTo: '/medical-history' },
            { id: '4', text: 'Plastic Surgery Price List', linkTo: '/plastic-price-list' },
            { id: '5', text: 'Dentistry Price List', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ],
        freepik: 'Crédits photo sur freepik.com'
    },
    {
        id: 'IT',
        disclaimer: "Questo sito web è stato progettato per fornire informazioni sui servizi medici e sulla salute. Tutte le informazioni sul sito sono ottenute da fonti professionali affidabili specializzate in un particolare campo medico. Qualsiasi copia e distribuzione di informazioni da questo sito Web non è consentita in conformità con la politica sulla privacy e sulla protezione dei dati.",
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Medical Files', linkTo: '/medical-history' },
            { id: '4', text: 'Plastic Surgery Price List', linkTo: '/plastic-price-list' },
            { id: '5', text: 'Dentistry Price List', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ],
        freepik: 'Crediti fotografici a freepik.com'
    },
    {
        id: 'ES',
        disclaimer: 'Este sitio web ha sido diseñado para brindar información sobre servicios médicos y salud. Toda la información del sitio se obtiene de fuentes profesionales confiables que se especializan en un campo médico en particular. Cualquier copia y distribución de información de este sitio web no está permitida de acuerdo con la política de privacidad y protección de datos.',
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Medical Files', linkTo: '/medical-history' },
            { id: '4', text: 'Plastic Surgery Price List', linkTo: '/plastic-price-list' },
            { id: '5', text: 'Dentistry Price List', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ], 
        freepik: 'Créditos de las fotos a freepik.com'
    },
    {
        id: 'PT',
        disclaimer: 'Este site foi projetado para fornecer informações sobre serviços médicos e de saúde. Todas as informações no site são obtidas de fontes profissionais confiáveis, especializadas em uma área médica específica. Qualquer cópia e distribuição de informações deste site não é permitida de acordo com a política de privacidade e proteção de dados.',
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Medical Files', linkTo: '/medical-history' },
            { id: '4', text: 'Plastic Surgery Price List', linkTo: '/plastic-price-list' },
            { id: '5', text: 'Dentistry Price List', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ],
        freepik: 'Créditos das fotos para freepik.com'
    },
    {
        id: 'RU',
        disclaimer: 'Этот веб-сайт был разработан для предоставления информации о медицинских услугах и здоровье. Вся информация на сайте получена из надежных профессиональных источников, специализирующихся в определенной области медицины. Любое копирование и распространение информации с этого веб-сайта запрещено в соответствии с политикой конфиденциальности и защиты данных.',
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Medical Files', linkTo: '/medical-history' },
            { id: '4', text: 'Plastic Surgery Price List', linkTo: '/plastic-price-list' },
            { id: '5', text: 'Dentistry Price List', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ],
        freepik: 'Фото предоставлено freepik.com'
    },
    {
        id: 'BG',
        disclaimer: 'Този уебсайт е проектиран да предоставя информация за медицинските услуги и здравето. Цялата информация на сайта е получена от надеждни професионални източници, специализирани в определена медицинска област. Всяко копиране и разпространение на информация от този уебсайт не е разрешено в съответствие с политиката за поверителност и защита на данните.',
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Medical Files', linkTo: '/medical-history' },
            { id: '4', text: 'Plastic Surgery Price List', linkTo: '/plastic-price-list' },
            { id: '5', text: 'Dentistry Price List', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ],
        freepik: 'Кредити за снимки към freepik.com'
    },
    {
        id: 'GR',
        disclaimer: 'Αυτός ο ιστότοπος έχει σχεδιαστεί για να παρέχει πληροφορίες σχετικά με τις ιατρικές υπηρεσίες και την υγεία. Όλες οι πληροφορίες στον ιστότοπο λαμβάνονται από αξιόπιστες επαγγελματικές πηγές που ειδικεύονται σε συγκεκριμένο ιατρικό τομέα. Δεν επιτρέπεται η αντιγραφή και διανομή πληροφοριών από αυτόν τον ιστότοπο σύμφωνα με την πολιτική προστασίας προσωπικών δεδομένων και δεδομένων.',
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Medical Files', linkTo: '/medical-history' },
            { id: '4', text: 'Plastic Surgery Price List', linkTo: '/plastic-price-list' },
            { id: '5', text: 'Dentistry Price List', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ],
        freepik: 'Φωτογραφικές πιστώσεις στο freepik.com'
    }
];

class Footer extends Component {
    handleClick = (pg) => {
        let url = '';
        switch(pg) {
            case 'facebook':
                url = 'https://www.facebook.com/healthiergeclinic';
                break;
            case 'instagram':
                url = 'https://www.instagram.com/healthierge.clinic';
                break;
            case 'linkedin':
                url = '';
                break;
            case 'twitter':
                url = 'https://twitter.com/healthiergeclinic';
                break;
            case 'email':
                url = 'mailto:info@healthierge.com';
                break;
            case 'whatsapp':
                url = 'https://api.whatsapp.com/send?phone=+905422358860&text=I want to get some information about:';
                break;
            case 'freepik':
                url = 'https://www.freepik.com';
                break;
            default:
                return (0);
        }
        const win = window.open(url, '_blank');
        if (win != null) {
              win.focus();
        }
        return (1);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (
        <div>
            <div className="Footer">
                <div className="FooterLogo">
                    <img src={HealthiergeLogo} width="150px" alt="Healthierge"/>
                    <div className="FooterClaim">
                        {lItem.disclaimer}
                    </div>
                </div>
                {
                    this.props.account.loggedIn ?
                    <div className="FooterCenter">
                        <div className="FooterCenterHeader">
                            {lItem.services}
                        </div>
                        {
                            lItem.servicesList.map((item,i) => (
                                <div key={i}>
                                <Link className="FooterCenterLink" to={item.linkTo}>{item.text}</Link>
                                </div>
                            ))
                        }
                    </div> : null
                }
                {
                    this.props.account.loggedIn ?
                    <div className="FooterCorporate">
                        <div className="FooterCorporateHeader">
                            {lItem.personal}
                        </div>
                        {
                            lItem.personalList.map((item,i) => (
                                <div key={i}>
                                <Link className="FooterCorporateLink" to={item.linkTo}>{item.text}</Link>
                                </div>
                            ))
                        }
                    </div> : null
                }
            </div>  
            <div className="FooterRight">
                <div className="FooterLinks">
                    <img className="FooterLink" src={facebook} alt="Facebook" height='50' onClick={() => this.handleClick('facebook')} />
                    <img className="FooterLink" src={instagram} alt="Instagram" height='50' onClick={() => this.handleClick('instagram')} />
                    <img className="FooterLink" src={twitter} alt="Twitter" height='50' onClick={() => this.handleClick('twitter')} />
                    <img className="FooterLink" src={email} alt="Email" height='50' onClick={() => this.handleClick('email')} />
                    <img className="FooterLink" src={whatsapp} alt="Whatsapp" height='50' onClick={() => this.handleClick('whatsapp')} />
                    {/*
                    <img className="FooterLink" src={linkedin} alt="LinkeIn" height='50' onClick={() => this.handleClick('linkedin')} />
                    */}
                </div>
                <div className="FooterCredits"
                    onClick={() => this.handleClick('freepik')}>
                    {lItem.freepik}
                </div>
            </div>
        </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    null
)(Footer);