import { PAYMENT } from '../actions/types';
import fetchStates from './fetchStates';

const payment = (state = {}, action) => {
    switch(action.type) {
        case PAYMENT.REQUEST:
            return { ...state, status: fetchStates.fetching };
        case PAYMENT.REQUEST_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case PAYMENT.REQUEST_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                ...action
            };
        default:
            return state;
    }
};

export default payment;