import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { invokeLogger } from '../actions/account';

import HealthiergeLogo from '../assets/healthierge-logo-claim-500.png';
import LanguageGB from '../assets/flag_gb.png';
/*
import LanguageDE from '../assets/flag_de.png';
import LanguageFR from '../assets/flag_fr.png';
import LanguageIT from '../assets/flag_it.png';
import LanguageES from '../assets/flag_es.png';
import LanguagePT from '../assets/flag_pt.png';
import LanguageRU from '../assets/flag_ru.png';
import LanguageBG from '../assets/flag_bg.png';
import LanguageGR from '../assets/flag_gr.png';
*/

import menuImg from '../assets/menuicon.png';

const lList = [
    {
        id: 'GB',
        home: 'Home',
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Our Doctors', linkTo: '/doctors' },
            { id: '2', text: 'Treatment Request', linkTo: '/treatment-request' },
            { id: '3', text: 'Plastic Surgery Prices', linkTo: '/plastic-price-list' },
            { id: '4', text: 'Dentistry Prices', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Account Settings', linkTo: '/account-settings' },
            { id: '2', text: 'My Medical Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interests', linkTo: '/interests' }
        ],
        about: "About",
        aboutList: [
            { id: '1', text: 'Contact Us', linkTo: '/contact-us' },
            { id: '2', text: 'Privacy Policy', linkTo: '/privacy-policy' }
        ],
        language: 'English',
        img: LanguageGB,
        logout: "Log Out"
    },/*
    {
        id: 'DE',
        home: 'Start',
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Unsere Ärzte', linkTo: '/doctors' },
            { id: '2', text: 'Behandlungsanfrage', linkTo: '/treatment-request' },
            { id: '3', text: 'Preise für plastische Chirurgie', linkTo: '/plastic-price-list' },
            { id: '4', text: 'Preise für Zahnmedizin', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Persönlich',
        personalList:[
            { id: '1', text: 'Kontoeinstellungen', linkTo: '/account-settings' },
            { id: '2', text: 'Mein medizinischer Status', linkTo: '/general-questions' },
            { id: '3', text: 'Interessen', linkTo: '/interests' }
        ],
        about: "Über",
        aboutList: [
            { id: '1', text: 'Kontaktiere uns', linkTo: '/contact-us' },
            { id: '2', text: 'Datenschutz-Bestimmungen', linkTo: '/privacy-policy' }
        ],
        language: 'Deutsch',
        img: LanguageDE,
        logout: "Ausloggen"
    },
    {
        id: 'FR',
        home: "Page d'Accueil",
        services: 'Services',
        servicesList: [
            { id: '1', text: 'Nos Médecins', linkTo: '/doctors' },
            { id: '2', text: 'Demande de Traitement', linkTo: '/treatment-request' },
            { id: '3', text: 'Prix de la Chirurgie Plastique', linkTo: '/plastic-price-list' },
            { id: '4', text: 'Prix de la Dentisterie', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Paramètres du Compte', linkTo: '/account-settings' },
            { id: '2', text: 'Mon statut Médical', linkTo: '/general-questions' },
            { id: '3', text: 'Intérêts', linkTo: '/interests' }
        ],
        about: "About",
        aboutList: [
            { id: '1', text: 'Contact Us', linkTo: '/contact-us' },
            { id: '2', text: 'Privacy Policy', linkTo: '/privacy-policy' }
        ],
        language: 'Français',
        img: LanguageFR,
        logout: "Se déconnecter"
    },
    {
        id: 'IT',
        home: 'Pagina Iniziale',
        services: 'Servizi',
        servicesList: [
            { id: '1', text: 'Nostri Medici', linkTo: '/doctors' },
            { id: '2', text: 'Richiesta di Trattamento', linkTo: '/treatment-request' },
            { id: '3', text: 'Prezzi della Chirurgia Plastica', linkTo: '/plastic-price-list' },
            { id: '4', text: 'Prezzi di Odontoiatria', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: "Impostazioni dell'Account", linkTo: '/account-settings' },
            { id: '2', text: 'Il mio Stato Medico', linkTo: '/general-questions' },
            { id: '3', text: 'Interessi', linkTo: '/interests' }
        ],
        about: "About",
        aboutList: [
            { id: '1', text: 'Contattaci', linkTo: '/contact-us' },
            { id: '2', text: 'Politica sulla Riservatezza', linkTo: '/privacy-policy' }
        ],
        language: 'Italiano',
        img: LanguageIT,
        logout: "Disconnettersi"
    },
    {
        id: 'ES',
        home: 'Página de Inicio',
        services: 'Servicios',
        servicesList: [
            { id: '1', text: 'Nuestras Doctores', linkTo: '/doctors' },
            { id: '2', text: 'Solicitud de Tratamiento', linkTo: '/treatment-request' },
            { id: '3', text: 'Precios de Cirugía Plástica', linkTo: '/plastic-price-list' },
            { id: '4', text: 'Precios de Odontología', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Configuraciones de la Cuenta', linkTo: '/account-settings' },
            { id: '2', text: 'Mi estado Médico', linkTo: '/general-questions' },
            { id: '3', text: 'Intereses', linkTo: '/interests' }
        ],
        about: "About",
        aboutList: [
            { id: '1', text: 'Contacta con Nosotras', linkTo: '/contact-us' },
            { id: '2', text: 'Política de Privacidad', linkTo: '/privacy-policy' }
        ],
        language: 'Español',
        img: LanguageES,
        logout: "Cerrar sesión"
    },
    {
        id: 'PT',
        home: 'Pagina inicial',
        services: 'Serviços',
        servicesList: [
            { id: '1', text: 'Nossos Doutores', linkTo: '/doctors' },
            { id: '2', text: 'Pedido de Tratamento', linkTo: '/treatment-request' },
            { id: '3', text: 'Preços de Cirurgia Plástica', linkTo: '/plastic-price-list' },
            { id: '4', text: 'Preços Odontologia', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Configurações da Conta', linkTo: '/account-settings' },
            { id: '2', text: 'Meu Status Médico', linkTo: '/general-questions' },
            { id: '3', text: 'Interesses', linkTo: '/interests' }
        ],
        about: "About",
        aboutList: [
            { id: '1', text: 'Entre em Contato Conosco', linkTo: '/contact-us' },
            { id: '2', text: 'Política de Privacidade', linkTo: '/privacy-policy' }
        ],
        language: 'Português',
        img: LanguagePT,
        logout: "Fechar Sessãoair"
    },
    {
        id: 'RU',
        home: 'Домашняя страница',
        services: 'Услуги',
        servicesList: [
            { id: '1', text: 'Наши врачи', linkTo: '/doctors' },
            { id: '2', text: 'Запрос на лечение', linkTo: '/treatment-request' },
            { id: '3', text: 'Цены на пластическую операцию', linkTo: '/plastic-price-list' },
            { id: '4', text: 'Цены на стоматологию', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Настройки аккаунта', linkTo: '/account-settings' },
            { id: '2', text: 'Мой медицинский статус', linkTo: '/general-questions' },
            { id: '3', text: 'Интересы', linkTo: '/interests' }
        ],
        about: "About",
        aboutList: [
            { id: '1', text: 'Связаться с нами', linkTo: '/contact-us' },
            { id: '2', text: 'Политика конфиденциальности', linkTo: '/privacy-policy' }
        ],
        language: 'Pусский',
        img: LanguageRU,
        logout: "Выйти"
    },
    {
        id: 'BG',
        home: 'Начална страница',
        services: 'Услуги',
        servicesList: [
            { id: '1', text: 'Нашите лекари', linkTo: '/doctors' },
            { id: '2', text: 'Искане за лечение', linkTo: '/treatment-request' },
            { id: '3', text: 'Цени на пластичната хирургия', linkTo: '/plastic-price-list' },
            { id: '4', text: 'Стоматологични цени', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Настройки на акаунта', linkTo: '/account-settings' },
            { id: '2', text: 'Моят медицински статус', linkTo: '/general-questions' },
            { id: '3', text: 'Интереси', linkTo: '/interests' }
        ],
        about: "About",
        aboutList: [
            { id: '1', text: 'Свържете се с нас', linkTo: '/contact-us' },
            { id: '2', text: 'Политика за поверителност', linkTo: '/privacy-policy' }
        ],
        language: 'Български',
        img: LanguageBG,
        logout: "Излезте"
    },
    {
        id: 'GR',
        home: 'Αρχική σελίδα',
        services: 'Υπηρεσίες',
        servicesList: [
            { id: '1', text: 'Οι γιατροί μας', linkTo: '/doctors' },
            { id: '2', text: 'Αίτημα θεραπείας', linkTo: '/treatment-request' },
            { id: '3', text: 'Τιμές πλαστικής χειρουργικής', linkTo: '/plastic-price-list' },
            { id: '4', text: 'Τιμές οδοντιατρικής', linkTo: '/dentistry-price-list' }
        ],
        personal: 'Personal',
        personalList:[
            { id: '1', text: 'Ρυθμίσεις λογαριασμού', linkTo: '/account-settings' },
            { id: '2', text: 'Η ιατρική μου κατάσταση', linkTo: '/general-questions' },
            { id: '3', text: 'Τα ενδιαφέροντα', linkTo: '/interests' }
        ],
        about: "About",
        aboutList: [
            { id: '1', text: 'Επικοινωνήστε μαζί μας', linkTo: '/contact-us' },
            { id: '2', text: 'Πολιτική απορρήτου', linkTo: '/privacy-policy' }
        ],
        language: 'Ελληνικά',
        img: LanguageGR,
        logout: "Αποσύνδεση"
    } */
];

class Menu extends Component {
    state = {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        smallMenuLimit: 768
    }

    getMenuSize = () => {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }

    componentDidMount() {
        window.addEventListener('resize', this.getMenuSize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.getMenuSize);
    }

    changeLanguage = (language) => {
        const id = this.props.account.id;
        const text = "Language changed by " + this.props.account.email + " to " + language;
        this.props.invokeLogger({ id, text });

        if (this.props.changeLanguage)
            this.props.changeLanguage(language);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (
            <div className="Menu">
                <div className="MenuLogo">
                    <a href="https://www.healthierge.com">
                        <img src={HealthiergeLogo} width="100%" alt='Healthierge' />
                    </a>
                </div>
                <div className="MenuContainer">
                {
                    this.props.account.loggedIn ? 
                    <Link className="MenuItem" to="/">{lItem.home}</Link>
                    : null
                }
                {
                    this.props.account.loggedIn &&
                    this.state.innerWidth >= this.state.smallMenuLimit ?
                    <div className="MenuSub">
                        <div className="MenuItem">{lItem.services}</div>
                        <div className="MenuSubContent">
                        {
                            lItem.servicesList.map((item,i) => (
                                <div className="MenuSubItem" key={i}>
                                <Link className="MenuSubItemItem" to={item.linkTo}>{item.text}</Link>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    : null
                }
                {
                    this.props.account.loggedIn &&
                    this.state.innerWidth >= this.state.smallMenuLimit ?
                    <div className="MenuSub">
                        <div className="MenuItem">{lItem.personal}</div>
                        <div className="MenuSubContent">
                        {
                            lItem.personalList.map((item,i) => (
                                <div className="MenuSubItem" key={i}>
                                <Link className="MenuSubItemItem" to={item.linkTo}>{item.text}</Link>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    : null
                }
                {
                    this.props.account.loggedIn &&
                    this.state.innerWidth >= this.state.smallMenuLimit ? 
                    <div className="MenuSub">
                        <div className="MenuItem">{lItem.about}</div>
                        <div className="MenuSubContent">
                        {
                            lItem.aboutList.map((item,i) => (
                                <div className="MenuSubItem" key={i}>
                                <Link className="MenuSubItemItem" to={item.linkTo}>{item.text}</Link>
                                </div>
                            ))
                        }
                        <div className="MenuSubItem MenuSubItemItem" onClick={this.props.logout}>{lItem.logout}</div>
                        </div>
                    </div>
                    : null
                }
                </div>

                <div className="MenuRightContainer">
                    {
                    this.props.account.loggedIn ? 
                    <div className="MenuShort">
                        <img className="MenuIcon" src={menuImg} alt="Healthierge" />
                        <div className="MenuShortContent">
                            {
                                lItem.servicesList.map((item,i) => (
                                    <div className="MenuShortItem" key={i}>
                                    <Link className="MenuSubItemItem" to={item.linkTo}>{item.text}</Link>
                                    </div>
                                ))
                            }
                            <div className="MenuShortItem"><Link className="MenuSubItemItem" to="/about-healthierge">{lItem.contact}</Link></div>
                        </div>
                    </div>
                    :
                    null
                    }
                    <div className="Language">
                        <img src={lItem.img} width="32px" alt='Healthierge' />
                        <div className="LanguageContent">
                        {
                            lList.map((item,i) => (
                                <div
                                    className="LanguageItem"
                                    key={item.id}
                                    onClick={() => this.changeLanguage(item.id)}
                                >
                                    <div>
                                    <img src={item.img} width="32px" alt='Healthierge' />
                                    </div><div>{item.language}</div>
                                </div>
                            ))
                        }
                        </div>  
                    </div>
                </div>
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Menu);