import { BACKEND } from '../secrets/config';
import { PAYMENT } from './types';

export const makePayment = (sendData) => dispatch => {
    dispatch({ type: PAYMENT.REQUEST });

    console.log('payment', sendData);
    let options = {
        method: 'POST',
        body: JSON.stringify({ sendData }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/Payment/Isbank`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: PAYMENT.REQUEST_ERROR, message: json.message });
            } else {
                dispatch({ type: PAYMENT.REQUEST_SUCCESS, ...json });
            }
        })
        .catch(error => dispatch({ type: PAYMENT.REQUEST, message: error.message }));
};