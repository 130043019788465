import { DOCTORS } from '../actions/types';
import fetchStates from './fetchStates';

const doctors = (state = {}, action) => {
    switch(action.type) {
        case DOCTORS.FETCH:
            return { ...state, status: fetchStates.fetching };
        case DOCTORS.FETCH_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case DOCTORS.FETCH_SUCCESS:
            return {
                ...state,
                list: action.doctors,
                status: fetchStates.success
            };
        default:
            return state;
    }
};

export default doctors;