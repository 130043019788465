import { BACKEND } from '../secrets/config';
import { TREATMENT } from './types';

export const sendTreatmentRequest = (saveData) => dispatch => {
    dispatch({ type: TREATMENT.INSERT });

    let options = {
        method: 'POST',
        body: saveData,
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/Treatment/Insert`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: TREATMENT.INSERT_ERROR, message: json.message });
            } else {
                dispatch({ type: TREATMENT.INSERT_SUCCESS, ...json });
            }
        })
        .catch(error => dispatch({ type: TREATMENT.INSERT, message: error.message }));
};

export const fetchTreatments = (data) => dispatch => {
    dispatch({ type: TREATMENT.FETCH });

    let options = {
        method: 'POST',
        body: data,
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/Treatment/Fetch`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: TREATMENT.FETCH_ERROR, message: json.message });
            } else {
                dispatch({ type: TREATMENT.FETCH_SUCCESS, ...json });
            }
        })
        .catch(error => dispatch({ type: TREATMENT.FETCH, message: error.message }));
};