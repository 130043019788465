import React, { Component } from 'react';
import { connect } from 'react-redux';

import HeaderSub from './HeaderSub';

import { invokeLogger } from '../actions/account';

const lList = [
    {
        id: "GB",
        header: "Contact Us",
        section1Header: "Whatsapp",
        section1Para1: "+90 542 235 88 60",
        section2Header: "Email",
        section2Para1: "info@healthierge.com",
        section3Header: "Facebook",
        section3Para1: "https://www.facebook.com/healthiergeclinic",
        section4Header: "Instagram",
        section4Para1: "https://www.instagram.com/healthierge.clinic",
        section5Header: "Twitter",
        section5Para1: "https://twitter.com/healthiergeclinic",
        section6Header: "Address",
        section6Para1: "Esentepe Mah. Kardeşler Cad.",
        section6Para2: "Koral Han Apt. No:40/A",
        section6Para3: "34394 Şişli – ISTANBUL",
        section7Header: "Phone",
        section7Para1: "+90 542 235 88 60",
    },
    {
        id: "DE",
        header: "Contact Us",
        section1Header: "Whatsapp",
        section1Para1: "+90 542 235 88 60",
        section2Header: "Email",
        section2Para1: "info@healthierge.com",
        section3Header: "Facebook",
        section3Para1: "https://www.facebook.com/healthiergeclinic",
        section4Header: "Instagram",
        section4Para1: "https://www.instagram.com/healthierge.clinic",
        section5Header: "Twitter",
        section5Para1: "https://twitter.com/healthiergeclinic",
        section6Header: "Address",
        section6Para1: "Esentepe Mah. Kardeşler Cad.",
        section6Para2: "Koral Han Apt. No:40/A",
        section6Para3: "34394 Şişli – ISTANBUL",
        section7Header: "Phone",
        section7Para1: "+90 542 235 88 60",
    },
    {
        id: "FR",
        header: "Contact Us",
        section1Header: "Whatsapp",
        section1Para1: "+90 542 235 88 60",
        section2Header: "Email",
        section2Para1: "info@healthierge.com",
        section3Header: "Facebook",
        section3Para1: "https://www.facebook.com/healthiergeclinic",
        section4Header: "Instagram",
        section4Para1: "https://www.instagram.com/healthierge.clinic",
        section5Header: "Twitter",
        section5Para1: "https://twitter.com/healthiergeclinic",
        section6Header: "Address",
        section6Para1: "Esentepe Mah. Kardeşler Cad.",
        section6Para2: "Koral Han Apt. No:40/A",
        section6Para3: "34394 Şişli – ISTANBUL",
        section7Header: "Phone",
        section7Para1: "+90 542 235 88 60",
    },
    {
        id: "IT",
        header: "Contact Us",
        section1Header: "Whatsapp",
        section1Para1: "+90 542 235 88 60",
        section2Header: "Email",
        section2Para1: "info@healthierge.com",
        section3Header: "Facebook",
        section3Para1: "https://www.facebook.com/healthiergeclinic",
        section4Header: "Instagram",
        section4Para1: "https://www.instagram.com/healthierge.clinic",
        section5Header: "Twitter",
        section5Para1: "https://twitter.com/healthiergeclinic",
        section6Header: "Address",
        section6Para1: "Esentepe Mah. Kardeşler Cad.",
        section6Para2: "Koral Han Apt. No:40/A",
        section6Para3: "34394 Şişli – ISTANBUL",
        section7Header: "Phone",
        section7Para1: "+90 542 235 88 60",
    },
    {
        id: "ES",
        header: "Contact Us",
        section1Header: "Whatsapp",
        section1Para1: "+90 542 235 88 60",
        section2Header: "Email",
        section2Para1: "info@healthierge.com",
        section3Header: "Facebook",
        section3Para1: "https://www.facebook.com/healthiergeclinic",
        section4Header: "Instagram",
        section4Para1: "https://www.instagram.com/healthierge.clinic",
        section5Header: "Twitter",
        section5Para1: "https://twitter.com/healthiergeclinic",
        section6Header: "Address",
        section6Para1: "Esentepe Mah. Kardeşler Cad.",
        section6Para2: "Koral Han Apt. No:40/A",
        section6Para3: "34394 Şişli – ISTANBUL",
        section7Header: "Phone",
        section7Para1: "+90 542 235 88 60",
    },
    {
        id: "PT",
        header: "Contact Us",
        section1Header: "Whatsapp",
        section1Para1: "+90 542 235 88 60",
        section2Header: "Email",
        section2Para1: "info@healthierge.com",
        section3Header: "Facebook",
        section3Para1: "https://www.facebook.com/healthiergeclinic",
        section4Header: "Instagram",
        section4Para1: "https://www.instagram.com/healthierge.clinic",
        section5Header: "Twitter",
        section5Para1: "https://twitter.com/healthiergeclinic",
        section6Header: "Address",
        section6Para1: "Esentepe Mah. Kardeşler Cad.",
        section6Para2: "Koral Han Apt. No:40/A",
        section6Para3: "34394 Şişli – ISTANBUL",
        section7Header: "Phone",
        section7Para1: "+90 542 235 88 60",
    },
    {
        id: "RU",
        header: "Contact Us",
        section1Header: "Whatsapp",
        section1Para1: "+90 542 235 88 60",
        section2Header: "Email",
        section2Para1: "info@healthierge.com",
        section3Header: "Facebook",
        section3Para1: "https://www.facebook.com/healthiergeclinic",
        section4Header: "Instagram",
        section4Para1: "https://www.instagram.com/healthierge.clinic",
        section5Header: "Twitter",
        section5Para1: "https://twitter.com/healthiergeclinic",
        section6Header: "Address",
        section6Para1: "Esentepe Mah. Kardeşler Cad.",
        section6Para2: "Koral Han Apt. No:40/A",
        section6Para3: "34394 Şişli – ISTANBUL",
        section7Header: "Phone",
        section7Para1: "+90 542 235 88 60",
    },
    {
        id: "BG",
        header: "Contact Us",
        section1Header: "Whatsapp",
        section1Para1: "+90 542 235 88 60",
        section2Header: "Email",
        section2Para1: "info@healthierge.com",
        section3Header: "Facebook",
        section3Para1: "https://www.facebook.com/healthiergeclinic",
        section4Header: "Instagram",
        section4Para1: "https://www.instagram.com/healthierge.clinic",
        section5Header: "Twitter",
        section5Para1: "https://twitter.com/healthiergeclinic",
        section6Header: "Address",
        section6Para1: "Esentepe Mah. Kardeşler Cad.",
        section6Para2: "Koral Han Apt. No:40/A",
        section6Para3: "34394 Şişli – ISTANBUL",
        section7Header: "Phone",
        section7Para1: "+90 542 235 88 60",
    },
    {
        id: "GR",
        header: "Contact Us",
        section1Header: "Whatsapp",
        section1Para1: "+90 542 235 88 60",
        section2Header: "Email",
        section2Para1: "info@healthierge.com",
        section3Header: "Facebook",
        section3Para1: "https://www.facebook.com/healthiergeclinic",
        section4Header: "Instagram",
        section4Para1: "https://www.instagram.com/healthierge.clinic",
        section5Header: "Twitter",
        section5Para1: "https://twitter.com/healthiergeclinic",
        section6Header: "Address",
        section6Para1: "Esentepe Mah. Kardeşler Cad.",
        section6Para2: "Koral Han Apt. No:40/A",
        section6Para3: "34394 Şişli – ISTANBUL",
        section7Header: "Phone",
        section7Para1: "+90 542 235 88 60",
    }
];

class ContactUs extends Component {
    componentDidMount() {
        const id = this.props.account.id;
        const text = "Contact Us: " + this.props.account.email;
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (  
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                <div className="Page">
                    <div className="PageSection">
                        {lItem.section1Header}
                    </div>
                    <div className="PageText">
                        {lItem.section1Para1}
                    </div>
                    <div className="PageSection">
                        {lItem.section2Header}
                    </div>
                    <div className="PageText">
                        {lItem.section2Para1}
                    </div>
                    <div className="PageSection">
                        {lItem.section3Header}
                    </div>
                    <div className="PageText">
                        {lItem.section3Para1}
                    </div>
                    <div className="PageSection">
                        {lItem.section4Header}
                    </div>
                    <div className="PageText">
                        {lItem.section4Para1}
                    </div>
                    <div className="PageSection">
                        {lItem.section5Header}
                    </div>
                    <div className="PageText">
                        {lItem.section5Para1}
                    </div>
                    <div className="PageSection">
                        {lItem.section6Header}
                    </div>
                    <div className="PageText">
                        <div>{lItem.section6Para1}</div>
                        <div>{lItem.section6Para2}</div>   
                        <div>{lItem.section6Para3}</div>
                    </div>
                    <div className="PageSection">
                        {lItem.section7Header}
                    </div>
                    <div className="PageText">
                        {lItem.section7Para1}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(ContactUs);