import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import fetchStates from '../reducers/fetchStates';
import { invokeLogger } from '../actions/account';
import HeaderSub from './HeaderSub';
import { sendTreatmentRequest } from '../actions/treatment';
import STATIC_TREATMENTS from '../data/treatment.json';

const lList = [
    {
        id: "GB",
        header: "Get Offer for a Treatment",
    }
];

class TreatmentRequest extends Component {
    state = {
        uFiles: [],
        fileList: [],
        type: this.props.type,
        subjectId: 'XX',
        description: '',
        other: '',
        treatmentSent: false,
        errorMessage: ''
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = "Treatment Request: " + this.props.account.email;
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);
    }

    getType() {
        switch (this.state.type) {
            case 'ntr': return 'New Treatment Request';
            default: return 'Undefined';
        } 
    }

    handleChange = (event) =>{
        this.setState({ [event.target.name] : event.target.value });
    }

    handleFileUpload = (event) => {
        const uFiles = event.target.files;
        this.setState({ uFiles : uFiles });
        const fileList = [];

        for(var i=0; i < uFiles.length; i++) {
            fileList.push(uFiles[i]);
        }

        this.setState({ fileList: fileList });
    }

    fileNames() {
        const uFiles = this.state.uFiles;
        const fileDiv = [];

        if (uFiles.length > 0) {
            if (this.state.treatmentSent)
                fileDiv.push(<div key={0}>Following files are uploaded to Healthierge:</div>);
            else
                fileDiv.push(<div key={0}>Following files will be uploaded to Healthierge:</div>);
        }
        for(var i=0; i < uFiles.length; i++) {
            fileDiv.push(<div key={i+1}>{uFiles[i].name}</div>);
        }

        return fileDiv;
    }

    checkValid() {
        if (this.state.subjectId === "XX") {
            this.setState({
                errorMessage: 'Please choose a subject from above list'
            });
            return false;
        } 

        if (this.state.description === "") {
            this.setState({
                errorMessage: 'Please enter your message as detailed as possible'
            });
            return false;
        }

        return true;
    }

    saveRequest = () => {
        const saveData = new FormData();
        const treatment = {};
        const uFiles = this.state.uFiles;

        switch (this.state.type) {
            case 'ntr':           
            default:
                if (this.checkValid()) {
                    treatment.id = this.props.account.id;
                    treatment.subjectId = this.state.subjectId;
                    treatment.other = this.state.other;
                    treatment.descriptionText = this.state.description;
                    treatment.email = this.props.account.email;
                    saveData.set('treatmentJson', JSON.stringify(treatment));

                    for(var i=0; i < uFiles.length; i++) {
                        saveData.append('uFiles', uFiles[i]);
                    }
                    this.props.sendTreatmentRequest(saveData);
                    this.setState({ treatmentSent: true });
                }
                break;
        }
    }

    treatmentSubject(subjectId) {
        if (subjectId === "XX")
            return "not selected";
        return STATIC_TREATMENTS[subjectId];
    }
    
    getTreatments() {
        const list = Object.keys(STATIC_TREATMENTS);
        let rList = [];
        for ( let i=0; i < list.length; i++ ) {
            rList.push(
                <option key={list[i]} value={list[i]}>
                    {STATIC_TREATMENTS[list[i]]}
                </option>
            );
        }
        return rList;
    }

    requestSent() {
        return (
            <div className="Page">
                <div className="PageText">
                    You can upload and store your medical history documents as well as
                    photographs safely in Healthierge Online Services. 
                    This way our medical partners will be better informed about your 
                    current conditions in assisting you on the topic that 
                    you're requesting assistance.
                </div>
                <div className="PageText">
                    Your treatment request has been created successfully as below. 
                    Please use your reference number in all your communications 
                    with us and all Healthierge partners.
                </div>
                <div className="PageBold">
                    Treatment Request Reference Number #
                    <span className="KirmiziMurekkep">
                        {this.props.treatment.reference}
                    </span>
                </div>
                <div className="PageText">
                    I'm interested in {this.treatmentSubject(this.props.treatment.subjectId)}
                </div>
                <div className="PageBold">
                    Other
                </div>
                <div className="PageText">
                    {this.props.treatment.other}
                </div>
                <div className="PageBold">
                    Message
                </div>
                <div className="PageText">
                    {this.props.treatment.descriptionText}
                </div>
                <div className="PageBold">
                    Timestamp
                </div>
                <div className="PageText">
                    {this.props.treatment.dateOfRequest}
                </div>
            </div>
        )
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        if (this.props.account.status !== fetchStates.fetching && this.state.treatmentSent) {
            return this.requestSent();
        }

        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                <div className="Page">
                    <div className="PageText">
                        You can upload and store your medical documents as well as
                        photographs safely in Healthierge Online Services. 
                        This way our medical partners will be better informed about your 
                        current conditions in assisting you on the topic that 
                        you're requesting assistance.
                    </div>

                    <div className="KullaniciGiris PageText">
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{ width: "90px"}}>Subject</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width: '300px'}}
                                as="select"
                                value={this.state.subjectId}
                                onChange={this.handleChange}
                                placeholder='Choose your treatment'
                                name='subjectId'
                            >
                                <option key='XX' value='XX'>Please select a topic</option>
                                {
                                    this.getTreatments()
                                }
                            </FormControl>  
                        </InputGroup>
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '90px' }}>Other</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width: '300px' }}
                                aria-label="other"
                                value={this.state.other}
                                onChange={this.handleChange}
                                placeholder="Enter the topic you need treatment"
                                name='other'
                            />
                        </InputGroup>
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '90px' }}>Message</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width: '300px' }}
                                as="textarea"
                                rows="10"
                                name="description"
                                value={this.state.message}
                                onChange={this.handleChange}
                                placeholder='Enter your message text'
                            />
                        </InputGroup>
                        <Form>
                        <Form.File 
                            id="files"
                            label="Please select 1 or more files to upload"
                            data-browse="Browse"
                            onChange={this.handleFileUpload}
                            custom
                            multiple
                        />
                        </Form>
                    </div>
                    <div className="PageText">
                        {/*
                        <div className="KullaniciGirisItem">
                            <label htmlFor="files" className="MyButton">Choose File(s)</label>
                            <input
                                id="files"
                                style={{ display: "none" }}
                                type="file"
                                onChange={this.handleFileUpload}
                                multiple
                            />
                        </div>
                        */}
                        Please provide images or pdf's that will help 
                        our partners to extract most possible medical information about 
                        your treatment in advance. Those images may 
                        be; pictures taken of the area for treatment from 
                        different angles, x-ray images, your medical reports, etc.
                        that you think will be valuable for your treatment.
                    </div>
                    <div className="PageText">
                        <div className="KullaniciGirisItem">               
                        {
                            this.fileNames()
                        }
                        </div>
                    </div>
                    <div className="PageText">
                    {
                    this.props.account.status === fetchStates.fetching ?
                    <div className="Spinner">
                        <Spinner animation="border" variant="secondary" /> Creating New Request
                    </div> : 
                    <Button variant="secondary" onClick={this.saveRequest}>Create New Request</Button>
                    }
                    </div>
                </div>
                {
                    this.state.errorMessage !== '' ? 
                    <div className="Warning">{this.state.errorMessage}</div> : null
                }
            </div>
        )
    }
}

export default connect(
    ({ account, treatment }) => ({ account, treatment }),
    { sendTreatmentRequest, invokeLogger }
)(TreatmentRequest);
