import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import queryString from 'query-string';
import { BACKEND } from '../secrets/config';
import fetchStates from '../reducers/fetchStates';
import { fetchDoctors } from '../actions/doctors';
import { invokeLogger } from '../actions/account';
import HeaderSub from './HeaderSub';

const lList = [
    {
        id: "GB",
        expertise: "Areas of Expertise"
    }
];

class DoctorPage extends Component {
    state = {
        doctorId: "",
        doctorIndex: 0,
        redirect: 'none'
    }

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        const id = this.props.account.id;
        let dr = "";

        if (parsed) {
            this.setState({ doctorId: parsed.id, doctorIndex: parsed.key });
            if (this.props.doctors.status === fetchStates.success) {
                dr = this.props.doctors.list[parsed.key];
            } else {
                dr = { title: "Page refresh, getting", firstName: "doctor", lastName: "from last", titleFooter: "visit" }
            }
        }
        const text = `Doctor (${dr.title + ' ' + 
        dr.firstName + ' ' + 
        dr.lastName + ' ' + 
        dr.titleFooter}): ${this.props.account.email}`;
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);

        if (this.props.doctors.list === undefined)
            this.props.fetchDoctors(id);
    }

    askForAppointment = (dr) => {
        let redirect=`/doctors/appointment-form?key=${this.state.doctorIndex}&id=${this.state.doctorId}`;
        this.setState({ redirect });
    }

    showExpertise(dr) {
        let list = [];
        let str = dr.areasOfExpertise;

        str.split('||').map((s, i) => list.push(<li key={i+1}>{s}</li>));
        return list;
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        if (this.props.doctors.status === fetchStates.success) {
            const drList = this.props.doctors.list;
            const dr = drList[this.state.doctorIndex];

            if (this.state.redirect === 'none') {
            return (
                <div>
                    <HeaderSub language={this.props.language} header={dr.title + ' ' + dr.firstName + ' ' + 
                            dr.lastName + ' ' + dr.titleFooter} />
                    <div className="Page">
                        <div className="PageSection">
                            {dr.department}
                        </div>
                        <div className="PageText">
                            {dr.introduction}
                        </div>
                        <div className="PageSection">
                            <img src={BACKEND.ADDRESS + dr.picturePath } alt='Healthierge' />
                        </div>
                        <div className="PageSection">{lItem.expertise}</div>
                        <ul className="PageBullet">
                            {this.showExpertise(dr)}
                        </ul>
                        <div className="PageSection">
                            Languages
                        </div>
                        <div className="PageText">
                            {dr.languages}
                        </div>
                        {
                            dr.footer === "" ?

                            <div className="PageText">
                            The fee for an online appointment (zoom meeting) 
                            with {dr.title + ' ' + 
                                dr.firstName + ' ' + 
                                dr.lastName + ' ' + 
                                dr.titleFooter} is {dr.visitAmount + ' ' + 
                                dr.visitCurrency}. 
                            The amount is to be
                            paid and visible in our accounts prior to your appointment.
                            Healthierge Online Services will recognize your payment 
                            and automatically authorize your entrance to your online 
                            appointment.
                            </div> : null
                        }
                        {
                            dr.additionalInfo === '' ? null :
                            <div className="PageText">{dr.additionalInfo}</div>      
                        }
                        {
                            dr.footer === "" ? null :
                            <div className="PageText">{dr.footer}.</div>
                        }
                        {
                            dr.footer === "" ?
                            <div className="PageText">
                                <Button
                                    variant="secondary"
                                    onClick={() => this.askForAppointment(dr)}>
                                    Ask for an Appointment
                                </Button>
                            </div> : null
                        }
                    </div>
                </div>
            )
            }
            return (<Redirect push to={this.state.redirect} />);
        }
        return <div className="Warning"><Spinner animation="border" variant="secondary" /></div>
    }
}

export default connect(
    ({ account, doctors }) => ({ account, doctors }),
    { invokeLogger, fetchDoctors }
)(DoctorPage);