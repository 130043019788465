import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import fetchStates from '../reducers/fetchStates';
import { updateAccount, invokeLogger } from '../actions/account';
import STATIC_COUNTRIES from '../data/country.json';

import HeaderSub from './HeaderSub';

const lList = [
    {
        id: "GB",
        header: "Account Settings",
        active: "Account active since",
        messageUpdate: "Please update your account information",
        fieldFirstName: "First Name",
        placeholderFirstName: "Enter your first name",
        fieldLastName: "Last Name",
        placeholderLastName: "Enter your last name",
        fieldGender: "Gender",
        placeholderGender: "Gender",
        selectGender: "Select your gender",
        selectMale: "Male",
        selectFemale: "Female",
        fieldPhone: "Mobile Phone",
        placeholderPhone: "Enter your mobile number including country code",
        fieldCountry: "Country",
        placeholderCountry: "Country",
        selectCountry: "Please select your country",
        fieldPreferredLanguage: "Preferred Language",
        placeholderPreferredLanguage: "Preferred Language",
        selectPreferredLanguage: "Please select your preferred language",
        fieldNewsletter: "Sign up to free Healthierge email newsletter",
        fieldChangePassword: "Check to change your password",
        fieldNewPassword: "New Password",
        placeholderNewPassword: "Enter your new password",
        buttonUpdate: "Update Account"
    },
    {
        id: "DE",
        header: "Kontoeinstellungen",
        active: "Konto aktiv seit",
        messageUpdate: "Bitte aktualisieren Sie Ihre Kontoinformationen",
        fieldFirstName: "Vorname",
        placeholderFirstName: "Geben Sie Ihren Vornamen ein",
        fieldLastName: "Nachname",
        placeholderLastName: "Geben Sie Ihren Nachnamen ein",
        fieldGender: "Geschlecht",
        placeholderGender: "Geschlecht",
        selectGender: "Wählen Sie Ihr Geschlecht",
        selectMale: "Männlich",
        selectFemale: "Weiblich",
        fieldPhone: "Handy",
        placeholderPhone: "Geben Sie Ihre Handynummer einschließlich Landesvorwahl ein",
        fieldCountry: "Land",
        placeholderCountry: "Land",
        selectCountry: "Bitte wählen Sie Ihr Land aus",
        fieldPreferredLanguage: "Bevorzugte Sprache",
        placeholderPreferredLanguage: "Bevorzugte Sprache",
        selectPreferredLanguage: "Bitte wählen Sie Ihre bevorzugte Sprache",
        fieldNewsletter: "Melden Sie sich für den kostenlosen Healthierge-E-Mail-Newsletter an",
        fieldChangePassword: "Checken, wenn Sie Ihr Passwort ändern möchten",
        fieldNewPassword: "Neues Passwort",
        placeholderNewPassword: "Geben Sie Ihr neues Passwort ein",
        buttonUpdate: "Konto aktualisieren"
    },
    {
        id: "FR",
        header: "Account Settings",
        active: "Account active since",
        messageUpdate: "Please update your account information",
        fieldFirstName: "First Name",
        placeholderFirstName: "Enter your first name",
        fieldLastName: "Last Name",
        placeholderLastName: "Enter your last name",
        fieldGender: "Gender",
        placeholderGender: "Gender",
        selectGender: "Select your gender",
        selectMale: "Male",
        selectFemale: "Female",
        fieldPhone: "Mobile Phone",
        placeholderPhone: "Enter your mobile number including country code",
        fieldCountry: "Country",
        placeholderCountry: "Country",
        selectCountry: "Please select your country",
        fieldPreferredLanguage: "Preferred Language",
        placeholderPreferredLanguage: "Preferred Language",
        selectPreferredLanguage: "Please select your preferred language",
        fieldNewsletter: "Sign up to free Healthierge email newsletter",
        fieldChangePassword: "Check to change your password",
        fieldNewPassword: "New Password",
        placeholderNewPassword: "Enter your new password",
        buttonUpdate: "Update Account"
    },
    {
        id: "IT",
        header: "Account Settings",
        active: "Account active since",
        messageUpdate: "Please update your account information",
        fieldFirstName: "First Name",
        placeholderFirstName: "Enter your first name",
        fieldLastName: "Last Name",
        placeholderLastName: "Enter your last name",
        fieldGender: "Gender",
        placeholderGender: "Gender",
        selectGender: "Select your gender",
        selectMale: "Male",
        selectFemale: "Female",
        fieldPhone: "Mobile Phone",
        placeholderPhone: "Enter your mobile number including country code",
        fieldCountry: "Country",
        placeholderCountry: "Country",
        selectCountry: "Please select your country",
        fieldPreferredLanguage: "Preferred Language",
        placeholderPreferredLanguage: "Preferred Language",
        selectPreferredLanguage: "Please select your preferred language",
        fieldNewsletter: "Sign up to free Healthierge email newsletter",
        fieldChangePassword: "Check to change your password",
        fieldNewPassword: "New Password",
        placeholderNewPassword: "Enter your new password",
        buttonUpdate: "Update Account"
    },
    {
        id: "ES",
        header: "Account Settings",
        active: "Account active since",
        messageUpdate: "Please update your account information",
        fieldFirstName: "First Name",
        placeholderFirstName: "Enter your first name",
        fieldLastName: "Last Name",
        placeholderLastName: "Enter your last name",
        fieldGender: "Gender",
        placeholderGender: "Gender",
        selectGender: "Select your gender",
        selectMale: "Male",
        selectFemale: "Female",
        fieldPhone: "Mobile Phone",
        placeholderPhone: "Enter your mobile number including country code",
        fieldCountry: "Country",
        placeholderCountry: "Country",
        selectCountry: "Please select your country",
        fieldPreferredLanguage: "Preferred Language",
        placeholderPreferredLanguage: "Preferred Language",
        selectPreferredLanguage: "Please select your preferred language",
        fieldNewsletter: "Sign up to free Healthierge email newsletter",
        fieldChangePassword: "Check to change your password",
        fieldNewPassword: "New Password",
        placeholderNewPassword: "Enter your new password",
        buttonUpdate: "Update Account"
    },
    {
        id: "PT",
        header: "Account Settings",
        active: "Account active since",
        messageUpdate: "Please update your account information",
        fieldFirstName: "First Name",
        placeholderFirstName: "Enter your first name",
        fieldLastName: "Last Name",
        placeholderLastName: "Enter your last name",
        fieldGender: "Gender",
        placeholderGender: "Gender",
        selectGender: "Select your gender",
        selectMale: "Male",
        selectFemale: "Female",
        fieldPhone: "Mobile Phone",
        placeholderPhone: "Enter your mobile number including country code",
        fieldCountry: "Country",
        placeholderCountry: "Country",
        selectCountry: "Please select your country",
        fieldPreferredLanguage: "Preferred Language",
        placeholderPreferredLanguage: "Preferred Language",
        selectPreferredLanguage: "Please select your preferred language",
        fieldNewsletter: "Sign up to free Healthierge email newsletter",
        fieldChangePassword: "Check to change your password",
        fieldNewPassword: "New Password",
        placeholderNewPassword: "Enter your new password",
        buttonUpdate: "Update Account"
    },
    {
        id: "RU",
        header: "Account Settings",
        active: "Account active since",
        messageUpdate: "Please update your account information",
        fieldFirstName: "First Name",
        placeholderFirstName: "Enter your first name",
        fieldLastName: "Last Name",
        placeholderLastName: "Enter your last name",
        fieldGender: "Gender",
        placeholderGender: "Gender",
        selectGender: "Select your gender",
        selectMale: "Male",
        selectFemale: "Female",
        fieldPhone: "Mobile Phone",
        placeholderPhone: "Enter your mobile number including country code",
        fieldCountry: "Country",
        placeholderCountry: "Country",
        selectCountry: "Please select your country",
        fieldPreferredLanguage: "Preferred Language",
        placeholderPreferredLanguage: "Preferred Language",
        selectPreferredLanguage: "Please select your preferred language",
        fieldNewsletter: "Sign up to free Healthierge email newsletter",
        fieldChangePassword: "Check to change your password",
        fieldNewPassword: "New Password",
        placeholderNewPassword: "Enter your new password",
        buttonUpdate: "Update Account"
    },
    {
        id: "BG",
        header: "Account Settings",
        active: "Account active since",
        messageUpdate: "Please update your account information",
        fieldFirstName: "First Name",
        placeholderFirstName: "Enter your first name",
        fieldLastName: "Last Name",
        placeholderLastName: "Enter your last name",
        fieldGender: "Gender",
        placeholderGender: "Gender",
        selectGender: "Select your gender",
        selectMale: "Male",
        selectFemale: "Female",
        fieldPhone: "Mobile Phone",
        placeholderPhone: "Enter your mobile number including country code",
        fieldCountry: "Country",
        placeholderCountry: "Country",
        selectCountry: "Please select your country",
        fieldPreferredLanguage: "Preferred Language",
        placeholderPreferredLanguage: "Preferred Language",
        selectPreferredLanguage: "Please select your preferred language",
        fieldNewsletter: "Sign up to free Healthierge email newsletter",
        fieldChangePassword: "Check to change your password",
        fieldNewPassword: "New Password",
        placeholderNewPassword: "Enter your new password",
        buttonUpdate: "Update Account"
    },
    {
        id: "GR",
        header: "Account Settings",
        active: "Account active since",
        messageUpdate: "Please update your account information",
        fieldFirstName: "First Name",
        placeholderFirstName: "Enter your first name",
        fieldLastName: "Last Name",
        placeholderLastName: "Enter your last name",
        fieldGender: "Gender",
        placeholderGender: "Gender",
        selectGender: "Select your gender",
        selectMale: "Male",
        selectFemale: "Female",
        fieldPhone: "Mobile Phone",
        placeholderPhone: "Enter your mobile number including country code",
        fieldCountry: "Country",
        placeholderCountry: "Country",
        selectCountry: "Please select your country",
        fieldPreferredLanguage: "Preferred Language",
        placeholderPreferredLanguage: "Preferred Language",
        selectPreferredLanguage: "Please select your preferred language",
        fieldNewsletter: "Sign up to free Healthierge email newsletter",
        fieldChangePassword: "Check to change your password",
        fieldNewPassword: "New Password",
        placeholderNewPassword: "Enter your new password",
        buttonUpdate: "Update Account"
    }
];

class AccountSettings extends Component {
    state = {
        firstName: this.props.account.firstName,
        lastName: this.props.account.lastName,
        gender: this.props.account.gender,
        phone: this.props.account.phone,
        country: this.props.account.country,
        preferredLanguage: this.props.account.preferredLanguage,
        newsletter: this.props.account.newsletter,
        password: '',
        passwordChange: false
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = "Account Settings: " + this.props.account.email;
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);
    }

    togglePasswordChange = (event) => {
        this.setState({ passwordChange: !this.state.passwordChange });
    }

    toggleNewsletter = (event) => {
        this.setState({ newsletter: !this.state.newsletter });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'preferredLanguage')
            this.changeLanguage(event.target.value);
    }

    changeLanguage = (lang) => {
        let language = 'GB';
        switch(lang) {
            case '2': language = 'DE'; break;
            case '3': language = 'FR'; break;
            case '4': language = 'IT'; break;
            case '5': language = 'ES'; break;
            case '6': language = 'PT'; break;
            case '7': language = 'RU'; break;
            case '8': language = 'BG'; break;
            case '9': language = 'GR'; break;
            case '1':
            case '0':
            default: language = 'GB'; break;
        }
        if (this.props.changeLanguage)
            this.props.changeLanguage(language);
    }

    checkValidity() {
        return true;
    }

    updateMyAccount = () => {
        let {
            firstName,
            lastName,
            gender,
            phone,
            country,
            preferredLanguage,
            newsletter,
            password
        } = this.state;

        let {
            id,
            userDisclaimer,
            accountStatus
        } = this.props.account;

        if (!this.state.passwordChange) {
            password = '';
        }

        if (this.checkValidity()) {
            this.props.updateAccount({
                id,
                firstName,
                lastName,
                gender,
                phone,
                country,
                preferredLanguage,
                newsletter,
                userDisclaimer,
                accountStatus,
                password
            });
        }
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        let { startDate } = this.props.account;

        let kayitTarihi = lItem.active + ' ' + startDate.substring(6, 8) + '/' 
                        + startDate.substring(4, 6) + '/' + startDate.substring(0,4);

        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                {
                    this.props.account.status === fetchStates.fetching ?
                    <div className="Warning">
                        <Spinner animation="border" variant="secondary" />
                    </div> : null
                }
                {
                    this.props.account.accountStatus === "I1" ||
                    this.props.account.firstName === "" ||
                    this.props.account.lastName === "" ||
                    this.props.account.phone === "" ||
                    this.props.account.gender === "" ||
                    this.props.account.country === "XX" ||
                    this.props.account.preferredLanguage === "0" ?
                    <div className="Warning">
                        {lItem.messageUpdate}
                    </div> : null
                }
                <div className="Page PageText KullaniciGiris">
                    <div className="SayfaTarih">
                        {kayitTarihi}
                    </div>
                    <InputGroup className="KullaniciGirisItem">
                        <InputGroup.Prepend>
                        <InputGroup.Text style={{ width : '170px' }}>{lItem.fieldFirstName}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            aria-label="firstName"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                            placeholder={lItem.placeholderFirstName}
                            name='firstName'
                        />
                    </InputGroup>
                    <InputGroup className="KullaniciGirisItem">
                        <InputGroup.Prepend>
                        <InputGroup.Text style={{ width : '170px' }}>{lItem.fieldLastName}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            placeholder={lItem.placeholderLastName}
                            name='lastName'
                        />
                    </InputGroup>
                    <InputGroup className="KullaniciGirisItem">
                        <InputGroup.Prepend>
                        <InputGroup.Text style={{ width : '170px' }}>{lItem.fieldGender}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            as="select"
                            value={this.state.gender}
                            onChange={this.handleChange}
                            placeholder={lItem.fieldGender}
                            name='gender'
                        >
                            <option key='' value=''>{lItem.selectGender}</option>
                            <option key='M' value='M'>{lItem.selectMale}</option>
                            <option key='F' value='F'>{lItem.selectFemale}</option>
                        </FormControl>  
                    </InputGroup>
                    <InputGroup className="KullaniciGirisItem">
                        <InputGroup.Prepend>
                        <InputGroup.Text style={{ width : '170px' }}>{lItem.fieldPhone}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={this.state.phone}
                            onChange={this.handleChange}
                            placeholder={lItem.placeholderPhone}
                            name='phone'
                        />
                    </InputGroup>
                    <InputGroup className="KullaniciGirisItem">
                        <InputGroup.Prepend>
                        <InputGroup.Text style={{ width : '170px' }}>{lItem.fieldCountry}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            as="select"
                            value={this.state.country}
                            onChange={this.handleChange}
                            placeholder={lItem.placeholderCountry}
                            name='country'
                        >
                            <option key='XX' value='XX'>{lItem.selectCountry}</option>
                            {
                                STATIC_COUNTRIES.map((item,i) => (
                                    <option key={item.ccode} value={item.ccode}>
                                        {item.country}
                                    </option>
                                ))
                            }
                        </FormControl>  
                    </InputGroup>
                    <InputGroup className="KullaniciGirisItem">
                        <InputGroup.Prepend>
                        <InputGroup.Text style={{ width : '170px' }}>{lItem.fieldPreferredLanguage}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            as="select"
                            value={this.state.preferredLanguage}
                            onChange={this.handleChange}
                            placeholder={lItem.placeholderPreferredLanguage}
                            name='preferredLanguage'
                        >
                            <option key='0' value='0'>{lItem.selectPreferredLanguage}</option>
                            <option key='GB' value='1'>English</option>
                            {/*
                            <option key='DE' value='2'>Deutch</option>
                            <option key='FR' value='3'>Français</option>
                            <option key='IT' value='4'>Italiano</option>
                            <option key='ES' value='5'>Español</option>
                            <option key='PT' value='6'>Português</option>
                            <option key='RU' value='7'>Pусский</option>
                            <option key='BG' value='8'>Български</option>
                            <option key='GR' value='9'>Ελληνικά</option>
                            */}
                        </FormControl>  
                    </InputGroup>
                    <div className="SayfaTarih">
                        Other languages coming soon..
                    </div>     
                    <div className="custom-control custom-checkbox PageText">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="newsletter"
                            onChange={this.toggleNewsletter}
                            checked={this.state.newsletter}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="newsletter">
                            {lItem.fieldNewsletter}
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox PageText">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="passwordChange"
                            onChange={this.togglePasswordChange}
                            checked={this.state.passwordChange}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="passwordChange">
                            {lItem.fieldChangePassword}
                        </label>
                    </div>
                    {
                        this.state.passwordChange ?
                        <div>
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '170px' }}>{lItem.fieldNewPassword}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                placeholder={lItem.placeholderNewPassword}
                                name='password'
                            />
                        </InputGroup>
                        </div> : null
                    }
                    {
                        this.props.account.status === fetchStates.fetching ? null :
                        <Button variant="secondary" onClick={this.updateMyAccount}>{lItem.buttonUpdate}</Button>
                    }
                </div>
                {
                    this.props.account.status === fetchStates.fetching ?
                    <div className="Warning">
                        <Spinner animation="border" variant="secondary" />
                    </div> : null
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { updateAccount, invokeLogger }
)(AccountSettings);