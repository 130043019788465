const ACCOUNT = {
    FETCH: 'ACCOUNT_FETCH',
    FETCH_ERROR: 'ACCOUNT_FETCH_ERROR',
    FETCH_SUCCESS: 'ACCOUNT_FETCH_SUCCESS',
    FETCH_LOGOUT_SUCCESS: 'ACCOUNT_FETCH_LOGOUT_SUCCESS',
    FETCH_AUTHENTICATED_SUCCESS: 'ACCOUNT_FETCH_AUTHENTICATED_SUCCESS',
    UPDATE: 'ACCOUNT_UPDATE',
    UPDATE_ERROR: 'ACCOUNT_UPDATE_ERROR',
    UPDATE_SUCCESS: 'ACCOUNT_UPDATE_SUCCESS',
    ACTIVATE: 'ACCOUNT_ACTIVATE',
    ACTIVATE_ERROR: 'ACCOUNT_ACTIVATE_ERROR',
    ACTIVATE_SUCCESS: 'ACCOUNT_ACTIVATE_SUCCESS',
    FORGOTTENPASSWORD: 'ACCOUNT_FORGOTTENPASSWORD',
    FORGOTTENPASSWORD_ERROR: 'ACCOUNT_FORGOTTENPASSWORD_ERROR',
    FORGOTTENPASSWORD_SUCCESS: 'ACCOUNT_FORGOTTENPASSWORD_SUCCESS',
    UPDATEDISCLAIMER: 'ACCOUNT_UPDATEDISCLAIMER',
    UPDATEDISCLAIMER_ERROR: 'ACCOUNT_UPDATEDISCLAIMER_ERROR',
    UPDATEDISCLAIMER_SUCCESS: 'ACCOUNT_UPDATEDISCLAIMER_SUCCESS',
    UPDATEINTERESTS: 'ACCOUNT_UPDATEINTERESTS',
    UPDATEINTERESTS_ERROR: 'ACCOUNT_UPDATEINTERESTS_ERROR',
    UPDATEINTERESTS_SUCCESS: 'ACCOUNT_UPDATEINTERESTS_SUCCESS',
    INVOKELOGGER: 'ACCOUNT_INVOKELOGGER',
    INVOKELOGGER_ERROR: 'ACCOUNT_INVOKELOGGER_ERROR',
    INVOKELOGGER_SUCCESS: 'ACCOUNT_INVOKELOGGER_SUCCESS',
    UPDATEGENERALQUESTIONS: 'ACCOUNT_UPDATEGENERALQUESTIONS',
    UPDATEGENERALQUESTIONS_ERROR: 'ACCOUNT_UPDATEGENERALQUESTIONS_ERROR',
    UPDATEGENERALQUESTIONS_SUCCESS: 'ACCOUNT_UPDATEGENERALQUESTIONS_SUCCESS'
};

const TREATMENT = {
    FETCH: 'TREATMENT_FETCH',
    FETCH_ERROR: 'TREATMENT_FETCH_ERROR',
    FETCH_SUCCESS: 'TREATMENT_FETCH_SUCCESS',
    INSERT: 'TREATMENT_INSERT',
    INSERT_ERROR: 'TREATMENT_INSERT_ERROR',
    INSERT_SUCCESS: 'TREATMENT_INSERT_SUCCESS'
};

const DOCTORS = {
    FETCH: 'DOCTORS_FETCH',
    FETCH_ERROR: 'DOCTORS_FETCH_ERROR',
    FETCH_SUCCESS: 'DOCTORS_FETCH_SUCCESS'
};

const APPOINTMENT = {
    REQUEST: 'APPOINTMENT_REQUEST',
    REQUEST_ERROR: 'APPOINTMENT_REQUEST_ERROR',
    REQUEST_SUCCESS: 'APPOINTMENT_REQUEST_SUCCESS'
};

const PAYMENT = {
    REQUEST: 'PAYMENT_REQUEST',
    REQUEST_ERROR: 'PAYMENT_REQUEST_ERROR',
    REQUEST_SUCCESS: 'PAYMENT_REQUEST_SUCCESS'
};

export { ACCOUNT, TREATMENT, APPOINTMENT, DOCTORS, PAYMENT };