import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { BACKEND } from '../secrets/config';

class CoverItem extends Component {
    state = {
        defaultBorderColor: 'BorderDefaultColor',
        redirect: 'none'
    }

    handleClick = () => {
        let redirect="/";

        if (this.props.callPage) {
            this.props.callPage(this.props.redirect);
        }

        if (this.props.picturePath !== null && this.props.picturePath !== undefined) {
            redirect=`/doctors/doctor?key=${this.props.index}&id=${this.props.id}`;
            this.setState({ redirect });
        }
    }

    handleMouseEnter = () => {
        this.setState({ defaultBorderColor: 'BorderHighlightColor' });
    }

    handleMouseLeave = () => {
        this.setState({ defaultBorderColor: 'BorderDefaultColor' });
    }

    render() {
        if (this.state.redirect === 'none') {
            return (
                <div className="Cover">
                    <div
                        className={"CoverItem " + this.state.defaultBorderColor}
                        onClick={this.handleClick}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        {
                            this.props.picturePath !== null &&
                            this.props.picturePath !== undefined ?
                            <div className="CoverItemDrImage">
                                <img src={BACKEND.ADDRESS + this.props.picturePath} alt='Healthierge' />
                            </div>
                            :
                            <div className="CoverItemImage">
                                <img src={this.props.image} alt="Healthierge" />
                            </div>
                        }
                        <div className="CoverItemHeader">
                            {this.props.header}
                        </div>
                    </div>
                </div>
            )
        }
        return (<Redirect push to={this.state.redirect} />);
    }
}

export default CoverItem;
  