import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { logout } from '../actions/account';

import Menu from './Menu';
import Banner from './Banner';
import Footer from './Footer';
import Tail from './Tail';

import Home from './Home';
import AuthForm from './AuthForm';

import PrivacyPolicy from './PrivacyPolicy';
import AccountSettings from './AccountSettings';
import InterestedIn from './InterestedIn';
import TreatmentRequest from './TreatmentRequest';
import GeneralQuestions from './GeneralQuestions';
import PlasticPriceList from './PlasticPriceList';
import DentistryPriceList from './DentistryPriceList';
import Doctors from './Doctors';
import DoctorPage from './DoctorPage';
import ContactUs from './ContactUs';
import AppointmentForm from './AppointmentForm';

class Root extends Component {
    state = {
        language: 'GB'
    }
    
    changeLanguage = (language) => {
        this.setState({ language });
    }

    logout = () => {
        const id = this.props.account.id;
        this.props.logout(id);
    }

    getPreferredLanguage = (lang) => {       
        switch (lang) {
            case 1 : lang = 'GB'; break;
            case 2 : lang = 'DE'; break;
            case 3 : lang = 'FR'; break;
            case 4 : lang = 'IT'; break;
            case 5 : lang = 'ES'; break;
            case 6 : lang = 'PT'; break;
            case 7 : lang = 'RU'; break;
            case 8 : lang = 'BG'; break;
            case 9 : lang = 'GR'; break;
            default: lang = 'GB'; break;
        }
        return lang;
    }
    
    componentDidMount() {
        if(this.props.account.loggedIn) {
            this.setState({ language: this.getPreferredLanguage( this.props.account.preferredLanguage )});
        }
    }

    render () {
        return (
            <BrowserRouter>
                <Menu
                    language={this.state.language}
                    changeLanguage={this.changeLanguage}
                    logout={this.logout}
                />
                {
                !this.props.account.loggedIn ?
                <AuthForm language={this.state.language} />
                :
                <Switch>
                    <Route path='/privacy-policy'>
                        <PrivacyPolicy language={this.state.language} />
                    </Route>
                    <Route path='/account-settings'>
                        <AccountSettings
                            language={this.state.language}
                            changeLanguage={this.changeLanguage}
                            getPreferredLanguage={this.getPreferredLanguage}
                        />
                    </Route>
                    <Route path='/interests'>
                        <InterestedIn language={this.state.language} />
                    </Route>
                    <Route path='/treatment-request'>
                        <TreatmentRequest
                            language={this.state.language}
                            type="ntr"
                            editMode
                        />
                    </Route>
                    <Route path='/general-questions'>
                        <GeneralQuestions language={this.state.language} />
                    </Route>
                    <Route path='/plastic-price-list'>
                        <PlasticPriceList language={this.state.language} />
                    </Route>
                    <Route path='/dentistry-price-list'>
                        <DentistryPriceList language={this.state.language} />
                    </Route>
                    <Route path='/doctors/doctor'>
                        <DoctorPage language={this.state.language} />
                    </Route>
                    <Route path='/doctors/appointment-form'>
                        <AppointmentForm language={this.state.language} />
                    </Route>
                    <Route path='/doctors'>
                        <Doctors language={this.state.language} />
                    </Route>
                    <Route path='/contact-us'>
                        <ContactUs language={this.state.language} />
                    </Route>
                    <Route path='/'>
                        <Home language={this.state.language} />
                    </Route>
                </Switch>
                }
                <Banner language={this.state.language} />
                <Footer language={this.state.language} />
                <Tail language={this.state.language} />
            </BrowserRouter>          
        )
    }
};

export default connect(
    ({ account }) => ({ account }),
    { logout }
)(Root);