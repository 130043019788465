import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const lList = [
    {
        id: 'GB',
        reserved1: '© 2019-2021',
        reserved2: 'healthierge.com',
        reserved3: 'All Rights Reserved',
        menu: [
            { name: "Contact Us", linkTo: "/contact-us" },
            { name: "Privacy Policy", linkTo: "/privacy-policy" }
        ]
    },
    {
        id: 'DE',
        reserved1: '© 2019-2021',
        reserved2: 'healthierge.com',
        reserved3: 'Alle Rechte vorbehalten',
        menu: [
            { name: "Kontaktiere uns", linkTo: "/contact-us" },
            { name: "Datenschutz-Bestimmungen", linkTo: "/privacy-policy" }
        ]
    },
    {
        id: 'FR',
        reserved1: '© 2019-2021',
        reserved2: 'healthierge.com',
        reserved3: 'Tous les droits sont réservés',
        menu: [
            { name: "Nous contacter", linkTo: "/contact-us" },
            { name: "Politique de confidentialité", linkTo: "/privacy-policy" }
        ]
    },
    {
        id: 'IT',
        reserved1: '© 2019-2021',
        reserved2: 'healthierge.com',
        reserved3: 'Tutti i diritti riservati',
        menu: [
            { name: "Contattaci", linkTo: "/contact-us" },
            { name: "Politica sulla riservatezza", linkTo: "/privacy-policy" }
        ]
    },
    {
        id: 'ES',
        reserved1: '© 2019-2021',
        reserved2: 'healthierge.com',
        reserved3: 'Reservados todos los derechos',
        menu: [
            { name: "Contacta", linkTo: "/contact-us" },
            { name: "Política de privacidad", linkTo: "/privacy-policy" }
        ]
    },
    {
        id: 'PT',
        reserved1: '© 2019-2021',
        reserved2: 'healthierge.com',
        reserved3: 'Todos os direitos reservados',
        menu: [
            { name: "Entre em contato conosco", linkTo: "/contact-us" },
            { name: "Política de Privacidade", linkTo: "/privacy-policy" }
        ]
    },
    {
        id: 'RU',
        reserved1: '© 2019-2021',
        reserved2: 'healthierge.com',
        reserved3: 'Все права защищены',
        menu: [
            { name: "Связаться с нами", linkTo: "/contact-us" },
            { name: "Политика конфиденциальности", linkTo: "/privacy-policy" }
        ]
    },
    {
        id: 'BG',
        reserved1: '© 2019-2021',
        reserved2: 'healthierge.com',
        reserved3: 'Всички права запазени',
        menu: [
            { name: "Свържете се с нас", linkTo: "/contact-us" },
            { name: "Политика за поверителност", linkTo: "/privacy-policy" }
        ]
    },
    {
        id: 'GR',
        reserved1: '© 2019-2021',
        reserved2: 'healthierge.com',
        reserved3: 'Όλα τα δικαιώματα διατηρούνται',
        menu: [
            { name: "Επικοινωνήστε μαζί μας", linkTo: "/contact-us" },
            { name: "Πολιτική απορρήτου", linkTo: "/privacy-policy" }
        ]
    }
];

class Tail extends Component {
    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (
            <div className="Tail">
                <div className="TailReserved">
                    {lItem.reserved1} <span className="TailReservedLight">{lItem.reserved2}</span> {lItem.reserved3}
                </div>
                {
                    this.props.account.loggedIn ? 
                    <div className="TailMenu">
                    {
                    lItem.menu.map((item,i) => (
                        <span key={i}>
                            <Link className="TailMenuItem" to={item.linkTo}>{item.name}</Link>
                        </span>
                    ))
                    }
                    </div> : null
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    null
)(Tail);