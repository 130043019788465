import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import HeaderSub from './HeaderSub';
import CoverItem from './CoverItem';

import accountSettings from '../assets/accountSettings.jpg';
import plasticPriceList from '../assets/plasticPriceList.jpg';
import dentistryPriceList from '../assets/dentistryPriceList.jpg';
import treatmentRequest from '../assets/treatmentRequest.jpg';
import generalQuestions from '../assets/generalQuestions.jpg';
import interestedIn from '../assets/interestedIn.jpg';

//import Chat from './Chat/Chat/Chat';

const lList = [
    {
        id: "GB",
        header: "We Care for your Health",
        cover: [
            {
                id: 'account',
                header: 'Account Settings',
                image: accountSettings,
                redirect: "/account-settings"
            },
            {
                id: 'medical',
                header: 'My Medical Status',
                image: generalQuestions,
                redirect: "/general-questions"
            },
            {
                id: 'plastic',
                header: 'Plastic Surgery Price List',
                image: plasticPriceList,
                redirect: "/plastic-price-list"
            },
            {
                id: 'dentistry',
                header: 'Dentistry Price List',
                image: dentistryPriceList,
                redirect: "/dentistry-price-list"
            },
            {
                id: 'treatment',
                header: 'Get Offer for Treatment',
                image: treatmentRequest,
                redirect: "/treatment-request"
            },
            {
                id: 'interests',
                header: 'Interests',
                image: interestedIn,
                redirect: "/interests"
            }
        ]
    }
];

class Home extends Component {
    state = {
        redirect: 'none'
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    callPage = (redirect) => {
        this.setState({ redirect });
    }

    accountSettingsUptoDate() {
        if (this.props.account.accountStatus === "I1") return false;
        if (this.props.account.firstName === "") return false;
        if (this.props.account.lastName === "") return false;
        if (this.props.account.phone === "") return false;
        if (this.props.account.gender === "") return false;
        if (this.props.account.country === "XX") return false;
        if (this.props.account.preferredLanguage === "0") return false;

        return true;
    }

    anyInterestEntered() {
        if (this.props.account.dental) return true;
        if (this.props.account.heart) return true;
        if (this.props.account.eye) return true;
        if (this.props.account.ear) return true;
        if (this.props.account.obesity) return true;
        if (this.props.account.spine) return true;
        if (this.props.account.knee) return true;
        if (this.props.account.checkup) return true;
        if (this.props.account.plastic) return true;
        if (this.props.account.hair) return true;
        if (this.props.account.other !== '') return true;

        return false;
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        if (this.state.redirect === 'none') {
        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                {
                    this.props.account.userDisclaimer === 0 ?
                    <div className="Warning">
                        ! Please read and accept the <span className="MaviMurekkep cursorPointer" onClick={() => this.callPage("/privacy-policy")}>Privacy Policy</span> !
                    </div> :
                    !this.accountSettingsUptoDate() ?
                    <div className="Warning">
                        ! Please update all data in your <span className="MaviMurekkep cursorPointer" onClick={() => this.callPage("/account-settings")}>Account Settings</span> !
                    </div> :
                    !this.anyInterestEntered() ?
                    <div className="Warning">
                        ! Please tell us about your <span className="MaviMurekkep cursorPointer" onClick={() => this.callPage("/interests")}>Interests</span> !
                    </div> : null
                }
                <div className="Cover">
                {
                    lItem.cover.map((item,i) => (
                        <CoverItem
                            key={i}
                            id={item.id}
                            header={item.header}
                            image={item.image}
                            redirect={item.redirect}
                            callPage={this.callPage}
                        />
                    ))
                }
                </div>
            </div>
        )
    }
    return (<Redirect push to={this.state.redirect} />);
    }
}

export default connect(
    ({ account }) => ({ account }),
    null
)(Home);