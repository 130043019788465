import { BACKEND } from '../secrets/config';
import { APPOINTMENT } from './types';

export const sendAppointmentRequest = (sendData) => dispatch => {
    dispatch({ type: APPOINTMENT.REQUEST });

    let options = {
        method: 'POST',
        body: JSON.stringify({ sendData }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/Appointment/Request`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: APPOINTMENT.REQUEST_ERROR, message: json.message });
            } else {
                dispatch({ type: APPOINTMENT.REQUEST_SUCCESS, ...json });
            }
        })
        .catch(error => dispatch({ type: APPOINTMENT.REQUEST, message: error.message }));
};