import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { fetchDoctors } from '../actions/doctors';
import fetchStates from '../reducers/fetchStates';
import { invokeLogger } from '../actions/account';
import HeaderSub from './HeaderSub';
import CoverItem from './CoverItem';

const lList = [
    {
        id: "GB",
        header: "Our Doctors - Medical Partners",
    }
];

class Doctors extends Component {
    componentDidMount() {
        const id = this.props.account.id;
        const text = "Doctors: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        if (this.props.doctors.list === undefined)
            this.props.fetchDoctors(id);

        window.scrollTo(0, 0);

    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        let drList = undefined;
        if (this.props.doctors.status === fetchStates.success)
            drList = this.props.doctors.list;

        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                {
                    this.props.doctors.status === fetchStates.fetching ?
                    <div className="Warning">
                        <Spinner animation="border" variant="secondary" /> {lItem.spinner}  Getting Doctors
                    </div> : null
                }
                <div className="Page">
                    <div className="PageText">
                        Healthierge is offering online appointments with our 
                        partners to all our customers world wide. If you need to
                        consult an expert, get a second opinion about your interested
                        medical topic, you can register and ask for an online 
                        appointment. The cost of appointments are different for different
                        experts and is visible on the card of each expert. After your payment
                        has been received; you will receive a zoom meeting invitation from 
                        Healthierge.
                    </div>
                    <div className="PageText">
                        Each Online Appointment is limited to 30 mins.
                    </div>
                    <div className="Cover">
                    {
                        this.props.doctors.status === fetchStates.success ?
                        drList.map((dr,i) => (
                            <CoverItem
                                key={i}
                                index={i}
                                id={dr.doctorId}
                                header={dr.title + ' ' + dr.firstName + ' ' + 
                                        dr.lastName + ' ' + dr.titleFooter}
                                image=""
                                picturePath={dr.picturePath}
                                redirect=""
                                callPage=""
                            />
                        ))
                        : null
                    }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account, doctors }) => ({ account, doctors }),
    { invokeLogger, fetchDoctors }
)(Doctors);