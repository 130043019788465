import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import Card from 'react-credit-cards';

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
} from "./PaymentUtils";

import "react-credit-cards/es/styles-compiled.css";

import { makePayment } from '../actions/payment.js';
import fetchStates from '../reducers/fetchStates';

class Payment extends Component {

    state = {
        number: "",
        name: "",
        expiry: "",
        cvc: "",
        issuer: "",
        focused: "",
        error: "",
        showMessage: false
    };

    handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            this.setState({ issuer });
        }
    };

    handleInputFocus = ({ target }) => {
        this.setState({
            focused: target.name
        });
    };

    handleInputChange = ({ target }) => {
        if (target.name === "number") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        }

        this.setState({ [target.name]: target.value, error: '' });
    };

    /*
    handleSubmit = e => {
        e.preventDefault();
        const { issuer } = this.state;
        const formData = [...e.target.elements]
            .filter(d => d.name)
            .reduce((acc, d) => {
            acc[d.name] = d.value;
            return acc;
            }, {});

        this.setState({ formData });        
        this.form.reset();
    };
    */

    validate = () => {
        const { name, number, expiry, cvc } = this.state;
        if ( name === '' || number === '' || expiry === '' || cvc === '') {
            this.setState({ error: 'Please enter all the data on your credit card' });
            return false;
        }
        return true;
    }

    makePayment = (e) => {
        if (this.validate()) {
            const { name, number, expiry, cvc, focused, issuer } = this.state;
            let id = this.props.account.id;
            let amount = this.props.amount;
            let currency = this.props.currency;
            this.props.makePayment({ id, name, number, expiry, cvc, focused, issuer, amount, currency });
            this.setState({ showMessage: true });
        }
    }

    render()  {
        const { name, number, expiry, cvc, focused, issuer } = this.state;

        return (
            <div className="Sayfa">
                <div className="SayfaBaslik">
                    Credit Card Payment
                </div>
                <div className="CreditCards">
                    <div className="Amex CardIssuerSize">
                        &nbsp;
                    </div>
                    <div className="Mastercard CardIssuerSize">
                        &nbsp;
                    </div>
                    <div className="Visa CardIssuerSize">
                        &nbsp;
                    </div>
                    <div className="Dinersclub CardIssuerSize">
                        &nbsp;
                    </div>
                </div>
                {
                    this.props.account.accountStatus === 'TT' ? null :
                    <div className="Message">(Under Construction)</div>
                }
                <br />
                <div className="KullaniciGiris">
                <div key="Payment">
                    <div className="App-payment">
                        <Card
                            number={number}
                            name={name}
                            expiry={expiry}
                            cvc={cvc}
                            focused={focused}
                            callback={this.handleCallback}
                        />
                        <br />
                        {
                            this.props.account.accountStatus === 'TT' ?
                        <form ref={c => (this.form = c)}>
                            <div className="form-group">
                            <input
                                type="tel"
                                name="number"
                                className="form-control"
                                placeholder="Your card number"
                                pattern="[\d| ]{16,22}"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            />
                            </div>
                            <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Your name as it is written on your card"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            />
                            </div>
                            <div className="row">
                            <div className="col-6">
                                <input
                                type="tel"
                                name="expiry"
                                className="form-control"
                                placeholder="Valid Thru"
                                pattern="\d\d/\d\d"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                />
                            </div>
                            <div className="col-6">
                                <input
                                type="tel"
                                name="cvc"
                                className="form-control"
                                placeholder="CVC"
                                pattern="\d{3,4}"
                                required
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                                />
                            </div>
                            </div>
                            <input type="hidden" name="issuer" value={issuer} />
                            <br />
                            {
                                this.props.payment.status === fetchStates.fetching ?
                                <Spinner animation="border" variant="danger" /> : null
                            }
                            {
                                this.state.error !== '' ?
                                    <div className="Error">{this.state.error}</div>: null
                            }
                            {
                                this.state.showMessage ?
                                    <div className="Message">{this.props.payment.message}</div>: null
                            }
                            <div className="form-actions">
                                <Button
                                    onClick={this.makePayment}
                                    disabled={this.props.payment.status === fetchStates.fetching}
                                >
                                    Pay Now
                                </Button>
                            </div>
                        </form>:
                        <div className="Message">
                            Soon you'll be able to pay with your credit card as 
                            well. Until then, please make your payments with
                            international bank transfer.
                        </div>
                        }
                    </div>
                  </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account, payment }) => ({ account, payment }),
    { makePayment }
)(Payment);