import React, { Component } from 'react';
import { connect } from 'react-redux';

import { invokeLogger } from '../actions/account';

import HeaderSub from './HeaderSub';

const priceUnit = "EUR";
const lList = [
    {
        id: "GB",
        header: "Dentistry Price List",
        col1Header: "Treatment Type",
        col2Header: `Price (${priceUnit})`,
        section: [
            {
                header: "Surgery and Implantology",
                cols:
                [
                    { item: "Tooth Extraction", price: "55" },
                    { item: "Impacted Tooth Extraction", price: "220" },
                    { item: "Dental Implant Xive Dentsply Sirona", price: "540" },
                    { item: "Dental Implant Astra Dentsply Sirona/Straumann ITI", price: "815" },
                    { item: "Crown On Implant-Porcelain", price: "275" },
                    { item: "Crown On Implant-Zirconium Base Porcelain", price: "330" },
                    { item: "Sinus Lifting(Per Sinus)", price: "415" },
                    { item: "Sinus Lifting(Materials)", price: "385" },
                    { item: "Implant Removing", price: "275" },
                    { item: "Augmentation", price: "440" },
                    { item: "Bone Grafting", price: "440" },
                    { item: "Bone Substitude", price: "275" },
                    { item: "Membran", price: "385" }
                ]
            },
            {
                header: "Prosthetics",
                cols:
                [
                    { item: "Temporary Crown", price: "25" },
                    { item: "Total Denture (One Jaw)", price: "825" },
                    { item: "Partial Denture (One Jaw)", price: "880" },
                    { item: "All On Four (4 Xive Dentsply Sirona Implant + Temporary denture + 12 porcelain crowns for One Jaw)", price: "7.735" }
                ]
            },
            {
                header: "Cosmetic Dentistry",
                cols:
                [
                    { item: "Porcelain Crown", price: "190" },
                    { item: "Zirconium Crown", price: "300" },
                    { item: "E-max Crown", price: "440" },
                    { item: "E-max Laminate Veneer", price: "440" },
                    { item: "Composite Laminate Veneer (Bonding)", price: "165" },
                    { item: "Tooth Whitening(Office + Home Kit)", price: "220" }
                ]
            },
            {
                header: "Periodontology",
                cols:
                [
                    { item: "Scaling and Polishing", price: "55" },
                    { item: "Gum Treatment With Laser (One Jaw)", price: "275" },
                    { item: "Gummy Smile Treatment", price: "550" },
                    { item: "Flap Operation(One Quadrant)", price: "245" },
                    { item: "Depigmentation with laser (One jaw)", price: "385" }
                ]
            },
            {
                header: "Restorative Treatment",
                cols:
                [
                    { item: "Inlay or Onlay (Porcelain)", price: "275" },
                    { item: "Composite Filling", price: "75" },
                    { item: "Night Guard", price: "220" },
                    { item: "Gummy Smile (Botox)", price: "220" },
                    { item: "Masseter Botox", price: "220" },
                    { item: "Root Canal Treatment (One Canal)", price: "110" },
                    { item: "Root Canal Treatment (Two Canals)", price: "200" },
                    { item: "Root Canal Treatment (Three Canals)", price: "290" }
                ]
            },
            {
                header: "Orthodontics",
                cols:
                [
                    { item: "Metal Brackets", price: "1.930" },
                    { item: "Porcelain Brackets", price: "2.760" },
                    { item: "Lingual Treatment", price: "5.525" },
                    { item: "Invisalign System (One Jaw)", price: "2.210" }
                ]
            },
            {
                header: "Children Dentistry",
                cols:
                [
                    { item: "Composite Filling", price: "55" },
                    { item: "Fissure Sealent (Per Tooth)", price: "35" },
                    { item: "Space Maintainer", price: "110" },
                    { item: "Exraction", price: "35" },
                    { item: "Fluor", price: "45" }
                ]
            }
        ],
        section1Para1: "In the below table, you can find Healthierge price list for some of our dentistry treatments. Please remember that the figures below are indicative prices and will be different for every patient. The real price can only be given to you, after you will have an online appointment with one of our doctors.",
        section1Para2: "Healthierge is in partnership with a number of hospitals and a big selection of doctors, all experts in their fields. The prices differ for hospitals and doctors.",
        section1Para3: "As your Health Concierge, Healthierge also offers accomodation, transfer and translation services as well as city tours and holiday services to all our customers. Please ask for more information, if you need assitance in any topic related to your travel needs.",
    }
];

class DentistryPriceList extends Component {
    componentDidMount() {
        const id = this.props.account.id;
        const text = "Dentistry Prices: " + this.props.account.email;
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                <div className="Page">
                    <div className="PageText">
                        {lItem.section1Para1}
                    </div>
                    <div className="PageTable">
                    {
                        lItem.section.map((section,i) => (
                            <div key={i}>
                                <div className="PageTableRow">
                                    <div className="PageTableHeader">
                                        {section.header}
                                    </div>
                                </div>
                                <div className="PageTableRowHeader">
                                    <div className="PageTableCell">
                                        {lItem.col1Header}
                                    </div>
                                    <div className="PageTableCell">
                                        {lItem.col2Header}
                                    </div>
                                </div>
                                {
                                    section.cols.map((cols,k) => (
                                        <div className="PageTableRow" key={k}>
                                            <div className="PageTableCell">
                                                {cols.item}
                                            </div>
                                            <div className="PageTableCell">
                                                {cols.price}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                    </div>
                    <div className="PageText">
                        {lItem.section1Para2}
                    </div>
                    <div className="PageText">
                        {lItem.section1Para3}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(DentistryPriceList);