import { BACKEND } from '../secrets/config';
import { DOCTORS } from './types';

export const fetchDoctors = (id) => dispatch => {
    dispatch({ type: DOCTORS.FETCH });

    return fetch(`${BACKEND.ADDRESS}/Doctors/Get`, {
        method: 'POST',
        body: JSON.stringify({id}),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' 
    })
    .then(response => response.json())
    .then(json => {
        if (json.type === 'error' ) {
            dispatch({ type: DOCTORS.FETCH_ERROR, message: json.message });
        } else {
            dispatch({ type: DOCTORS.FETCH_SUCCESS, ...json });
        }
    })
    .catch(error => dispatch({ type: DOCTORS.FETCH_ERROR, message: error.message }));
}