import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import onlineImg from '../assets/online_app.png'

const lList = [
    {
        id: 'GB',
        header: 'Online Appointment',
        para1: 'Healthierge brings our expert doctors anywhere around the world to your home online. We cover all the healthcare departments with well known professors and doctors in their fields. Talk to our doctors from your living room. You can also enter your treatment requests, get consultation from our partners and upload your medical documents safely after having signed up.',
        para2: "If you are already a member, please sign in to ask for an online appointment. If you are new to Healthierge please register and start benefiting from our services."
    },
    {
        id: 'DE',
        header: 'Online-Termin',
        para1: 'Healthierge bringt unsere erfahrenen Ärzte auf der ganzen Welt online zu Ihnen nach Hause. Wir decken alle Gesundheitsabteilungen mit bekannten Professoren und Ärzten auf ihrem Gebiet ab. Bitte registrieren Sie sich (melden Sie sich an) oder melden Sie sich bei Healthierge an, um jetzt Ihren Online-Termin zu erhalten. Sprechen Sie mit unseren Ärzten aus Ihrem Wohnzimmer. Sie können auch Ihre Behandlungsanfragen eingeben, sich von unseren Partnern beraten lassen und Ihre medizinischen Dokumente nach der Anmeldung sicher hochladen.',
        para2: "If you are already a member, please sign in to ask for an online appointment. If you are new to Healthierge please register and start benefiting from our services."
    },
    {
        id: 'FR',
        header: 'Rendez-vous en Ligne',
        para1: 'Healthierge amène nos médecins experts partout dans le monde à votre domicile en ligne. Nous couvrons tous les départements de santé avec des professeurs et médecins renommés dans leurs domaines. Parlez à nos médecins depuis votre salon. Vous pouvez également saisir vos demandes de soins, consulter nos partenaires et télécharger vos documents médicaux en toute sécurité après votre inscription.',
        para2: "If you are already a member, please sign in to ask for an online appointment. If you are new to Healthierge please register and start benefiting from our services."
    },
    {
        id: 'IT',
        header: 'Appuntamento Online',
        para1: 'Healthierge porta i nostri medici esperti ovunque nel mondo a casa tua online. Copriamo tutti i reparti sanitari con professori e medici noti nei loro campi. Parla con i nostri medici dal tuo soggiorno. Puoi anche inserire le tue richieste di trattamento, ottenere una consulenza dai nostri partner e caricare i tuoi documenti medici in modo sicuro dopo esserti registrato.',
        para2: "If you are already a member, please sign in to ask for an online appointment. If you are new to Healthierge please register and start benefiting from our services."
    },
    {
        id: 'ES',
        header: 'Cita en Línea',
        para1: 'Healthierge trae a nuestros médicos expertos de cualquier parte del mundo a su hogar en línea. Cubrimos todos los departamentos de salud con profesores y médicos reconocidos en sus campos. Habla con nuestros médicos desde tu sala de estar. También puede ingresar sus solicitudes de tratamiento, obtener consultas de nuestros socios y cargar sus documentos médicos de manera segura después de haberse registrado.',
        para2: "If you are already a member, please sign in to ask for an online appointment. If you are new to Healthierge please register and start benefiting from our services."
    },
    {
        id: 'PT',
        header: 'Consulta Online',
        para1: 'Healthierge traz nossos médicos especialistas em qualquer lugar do mundo para sua casa online. Cobrimos todos os departamentos de saúde com professores e médicos renomados em suas áreas. Fale com nossos médicos da sua sala de estar. Você também pode inserir suas solicitações de tratamento, consultar nossos parceiros e fazer o upload de seus documentos médicos com segurança após a inscrição.',
        para2: "If you are already a member, please sign in to ask for an online appointment. If you are new to Healthierge please register and start benefiting from our services."
    },
    {
        id: 'RU',
        header: 'Онлайн-запись',
        para1: 'Healthierge приглашает наших опытных врачей из любой точки мира к вам домой в режиме онлайн. Мы охватываем все медицинские учреждения известными профессорами и докторами в своих областях. Поговорите с нашими врачами из своей гостиной. Вы также можете ввести свои запросы на лечение, получить консультацию у наших партнеров и безопасно загрузить свои медицинские документы после регистрации.',
        para2: "If you are already a member, please sign in to ask for an online appointment. If you are new to Healthierge please register and start benefiting from our services."
    },
    {
        id: 'BG',
        header: 'Онлайн среща',
        para1: 'Healthierge довежда нашите експерти лекари навсякъде по света във вашия дом онлайн. Ние покриваме всички здравни отдели с добре познати професори и лекари в своите области. Говорете с нашите лекари от вашия хол. Можете също така да въведете своите заявки за лечение, да получите консултация от нашите партньори и да качите вашите медицински документи безопасно, след като сте се регистрирали.',
        para2: "If you are already a member, please sign in to ask for an online appointment. If you are new to Healthierge please register and start benefiting from our services."
    },
    {
        id: 'GR',
        header: 'Online ραντεβού',
        para1: 'Η Healthierge φέρνει τους ειδικούς γιατρούς μας οπουδήποτε σε όλο τον κόσμο στο σπίτι σας στο διαδίκτυο. Καλύπτουμε όλα τα τμήματα υγειονομικής περίθαλψης με γνωστούς καθηγητές και γιατρούς στους τομείς τους. Μιλήστε με τους γιατρούς μας από το σαλόνι σας. Μπορείτε επίσης να υποβάλετε τα αιτήματά σας για θεραπεία, να λάβετε συμβουλές από τους συνεργάτες μας και να ανεβάσετε τα ιατρικά σας έγγραφα με ασφάλεια μετά την εγγραφή σας.',
        para2: "If you are already a member, please sign in to ask for an online appointment. If you are new to Healthierge please register and start benefiting from our services."
    }
];

class Banner extends Component {
    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (
            <div className="Banner">
                <img src={onlineImg} alt="Healthierge" />
                <div className="BannerHeader">
                    {lItem.header}
                </div>
                <div className="BannerText">
                    {lItem.para1}
                </div>
                {
                    this.props.account.loggedIn ?
                    <div className="BannerHeader">
                        <Link className="MyButton btn-secondary" to="/doctors/appointment-form">{lItem.header}</Link>
                    </div>:
                    <div className="BannerText">
                        {lItem.para2}
                    </div>
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    null
)(Banner);