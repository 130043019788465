import React from 'react';
//MAM import { createStore, compose, applyMiddleware } from 'redux';
import { createStore, applyMiddleware } from 'redux'; //MAM
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'; //MAM

import { Provider } from 'react-redux';
import { render } from 'react-dom';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

import Root from './components/Root';
import { fetchAuthenticated } from './actions/account';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

//MAM const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeEnhancer = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk))
);

store.dispatch(fetchAuthenticated({
    id: "m",
    healthiergeSerialNumber: localStorage.getItem("healthiergeSerialNumber"),
    healthiergeDateOfFirstVisit: localStorage.getItem("healthiergeDateOfFirstVisit"),
    healthiergeAccountId: localStorage.getItem("healthiergeAccountId"),
    language: window.navigator.language,
    platform: window.navigator.platform,
    vendor: window.navigator.vendor,
    appName: window.navigator.appName,
    userAgent: window.navigator.userAgent,
    product: window.navigator.product,
    productSub: window.navigator.productSub,
    cookie: window.navigator.cookieEnabled
}))
    .then(() => {
        render(
            <Provider store={store}>
                <Root />
            </Provider>,
            document.getElementById('root')
        );
    });