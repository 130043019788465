import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import fetchStates from '../reducers/fetchStates';
import { updateDisclaimer, invokeLogger } from '../actions/account';


import HeaderSub from './HeaderSub';

const lList = [
    {
        id: "GB",
        spinner: "Updating",
        agree: "I agree to and accept the privacy policy",
        header: "Privacy Policy",
        section1Para1: "This policy was last updated on December 20, 2020.",
        section2Header: "Web privacy policy",
        section2Para1: "We take your privacy seriously, and we want you to know how we collect, use, share and protect your information. In addition to this privacy policy, users of Healthierge should consult the site terms of use as well as any product specific terms and conditions that apply. You may review policies specifically related to patient information (protected health information, or PHI) submitted through Healthierge Online Services portal. This policy applies to healthierge.com, my.healthierge.com and other Web locations under Healthierge's control. Other Healthierge online properties may have their own privacy policies that apply to those sites. You should review those privacy policies in connection with your use of those sites.",
        section3Header: "What information we collect",
        section3Para1: "Information you give us: We respect the right to privacy of all visitors to the Healthierge sites. We receive and store information you enter on our sites or give us in any other way, such as name, email address and phone number. This includes information you submit on forms, such as appointment request forms. Some forms collect sensitive information, such as health information, necessary for us to provide our services to you.",
        section3Para2: "Information we collect automatically: When you interact with our sites, certain information about your use of our sites is automatically collected. This information includes computer and connection information, such as statistics on your page views, traffic to and from our sites, referral URL, ad data, your IP address, and device identifiers. This information also may include your transaction history, and your web log information, how you search for our sites, the websites you click on from our sites or emails, whether and when you open our emails, and your browsing activities across other websites.",
        section3Para3: "Much of this information is collected through cookies, web beacons and other tracking technologies, as well as through your web browser or device (e.g., IP address, MAC address, browser version, etc.).",
        section4Header: "Email communications, newsletter and related services",
        section4Para1: "Healthierge provides you with the opportunity to receive communications from us or third parties. You can sign up for a free Healthierge email newsletter. You can unsubscribe from this newsletter at any time.",
        section4Para2: "Email communications that you send to us via the email links on our site may be shared with a customer service representative, employee, medical expert, medical institutions we collaborate or agent that is most able to address your inquiry. We make every effort to respond in a timely fashion once communications are received. Once we have responded to your communication, it is discarded or archived, depending on the nature of the inquiry.",
        section4Para3: "The email functionality on our site does not provide a completely secure and confidential means of communication. It's possible that your email communication may be accessed or viewed by another internet user while in transit to us. If you wish to keep your communication private, do not use our email.",
        section4Para4: "You may decide at some point that you no longer wish to receive communications from our site. To stop receiving communications, you can simply sign in to our Online Services Center and unselect related option.",
        section5Header: "Surveys",
        section5Para1: "We occasionally survey visitors to our site. The information from these surveys is used in aggregated, de-identified form to help us understand the needs of our visitors so that we can improve our site. The information may be shared with third parties with whom we have a business relationship. We generally do not ask for information in surveys that would personally identify you; if we do request contact information for follow-up, you may decline to provide it. If survey respondents provide personal information (such as an email address) in a survey, it is shared only with those people who need to see it to respond to the question or request, or with third parties who perform data management services for our site. Those third parties have agreed to keep all data from surveys confidential.",
        section6Header: "How we use the information we collect",
        section6Para1: "We use the information we collect for things like:",
        section6Bullet1: "Optimizing the performance and user experience of our sites",
        section6Bullet2: "Operating, evaluating and improving our business",
        section6Bullet3: "Fulfilling orders and requests for products, services or information",
        section6Bullet4: "Processing returns and exchanges",
        section6Bullet5: "Tracking and confirming online orders",
        section6Bullet6: "Delivering or installing products",
        section6Bullet7: "Marketing and advertising products and services, including identifying you on our websites and tailoring advertisements and offers to you (both on our websites and on other websites) based on your interactions with us in our stores and online",
        section6Bullet8: "Conducting research and analysis",
        section6Bullet9: "Communicating with you about your account, special events and surveys",
        section6Bullet10: "Establishing and managing your accounts with us",
        section6Para2: "We may combine this information with any other information we have about you, including, if you are a Healthierge customer, any PHI we have about you. If we combine this information with your PHI, we will treat all of that information as PHI, and will only use or disclose that information as set forth in our notice of privacy practices."
    },
    {
        id: "DE",
        spinner: "Aktualisierung",
        agree: "Ich stimme den Datenschutzbestimmungen zu und akzeptiere sie",
        header: "Datenschutzerklärung",
        section1Para1: "Diese Richtlinie wurde zuletzt am 20. Dezember 2020 aktualisiert.",
        section2Header: "Web-Datenschutzrichtlinie",
        section2Para1: "Wir nehmen Ihre Privatsphäre ernst und möchten, dass Sie wissen, wie wir Ihre Daten sammeln, verwenden, teilen und schützen. Zusätzlich zu dieser Datenschutzrichtlinie sollten Benutzer von Healthierge die Nutzungsbedingungen der Website sowie alle produktspezifischen Informationen konsultieren Es gelten die allgemeinen Geschäftsbedingungen. Sie können Richtlinien überprüfen, die sich speziell auf Patienteninformationen (geschützte Gesundheitsinformationen oder PHI) beziehen, die über das Healthierge Online Services-Portal übermittelt wurden. Diese Richtlinien gelten für healthierge.com, my.healthierge.com und andere Websites, die unter der Kontrolle von Healthierge stehen Andere Healthierge-Online-Eigenschaften haben möglicherweise ihre eigenen Datenschutzrichtlinien, die für diese Websites gelten. Sie sollten diese Datenschutzrichtlinien im Zusammenhang mit Ihrer Nutzung dieser Websites überprüfen. ",
        section3Header: "Welche Informationen sammeln wir",
        section3Para1: "Informationen, die Sie uns geben: Wir respektieren das Recht auf Privatsphäre aller Besucher der Healthierge-Websites. Wir empfangen und speichern Informationen, die Sie auf unseren Websites eingeben oder uns auf andere Weise mitteilen, wie Name, E-Mail-Adresse und Telefonnummer. Dies schließt Informationen ein, die Sie auf Formularen einreichen, z. B. Terminanforderungsformulare. Einige Formulare erfassen vertrauliche Informationen, z. B. Gesundheitsinformationen, die erforderlich sind, damit wir Ihnen unsere Dienste anbieten können. ",
        section3Para2: "Informationen, die wir automatisch erfassen: Wenn Sie mit unseren Websites interagieren, werden bestimmte Informationen über Ihre Nutzung unserer Websites automatisch erfasst. Diese Informationen umfassen Computer- und Verbindungsinformationen, z. B. Statistiken zu Ihren Seitenaufrufen, zum Verkehr von und zu unseren Websites. Verweis-URL, Anzeigendaten, Ihre IP-Adresse und Gerätekennungen. Diese Informationen können auch Ihren Transaktionsverlauf und Ihre Weblog-Informationen enthalten, wie Sie nach unseren Websites suchen, auf welche Websites Sie von unseren Websites aus klicken oder welche E-Mails Sie wann und wann senden Sie öffnen unsere E-Mails und Ihre Browsing-Aktivitäten auf anderen Websites. ",
        section3Para3: "Ein Großteil dieser Informationen wird über Cookies, Web Beacons und andere Tracking-Technologien sowie über Ihren Webbrowser oder Ihr Gerät (z. B. IP-Adresse, MAC-Adresse, Browserversion usw.) gesammelt.",
        section4Header: "E-Mail-Kommunikation, Newsletter und verwandte Dienste",
        section4Para1: "Healthierge bietet Ihnen die Möglichkeit, Mitteilungen von uns oder Dritten zu erhalten. Sie können sich für einen kostenlosen Healthierge-E-Mail-Newsletter anmelden. Sie können diesen Newsletter jederzeit abbestellen.",
        section4Para2: "E-Mail-Mitteilungen, die Sie uns über die E-Mail-Links auf unserer Website senden, können an einen Kundendienstmitarbeiter, einen Mitarbeiter, einen medizinischen Experten, medizinische Einrichtungen, mit denen wir zusammenarbeiten, oder einen Vertreter, der Ihre Anfrage am besten bearbeiten kann, weitergegeben werden. Wir bemühen uns nach Kräften um rechtzeitig zu antworten, sobald Mitteilungen eingegangen sind. Sobald wir auf Ihre Mitteilung geantwortet haben, wird sie je nach Art der Anfrage verworfen oder archiviert. ",
        section4Para3: "Die E-Mail-Funktionalität auf unserer Website bietet kein vollständig sicheres und vertrauliches Kommunikationsmittel. Es ist möglich, dass Ihre E-Mail-Kommunikation während der Übertragung zu uns von einem anderen Internetbenutzer abgerufen oder angezeigt wird. Wenn Sie Ihre Kommunikation privat halten möchten , benutze unsere E-Mail nicht. ",
        section4Para4: "Sie können irgendwann entscheiden, dass Sie keine Mitteilungen mehr von unserer Website erhalten möchten. Um keine Mitteilungen mehr zu erhalten, können Sie sich einfach bei unserem Online Services Center anmelden und die entsprechende Option deaktivieren.",
        section5Header: "Umfragen",
        section5Para1: "Wir befragen gelegentlich Besucher unserer Website. Die Informationen aus diesen Umfragen werden in aggregierter, nicht identifizierter Form verwendet, um die Bedürfnisse unserer Besucher zu verstehen und unsere Website zu verbessern. Die Informationen können an Dritte weitergegeben werden Mit wem wir eine Geschäftsbeziehung unterhalten. In Umfragen, die Sie persönlich identifizieren würden, werden wir im Allgemeinen nicht um Informationen gebeten. Wenn wir Kontaktinformationen zur Nachverfolgung anfordern, können Sie die Bereitstellung dieser Informationen ablehnen. Wenn die Umfrageteilnehmer persönliche Informationen angeben (z Eine E-Mail-Adresse) wird in einer Umfrage nur an diejenigen Personen weitergegeben, die sie zur Beantwortung der Frage oder Anfrage benötigen, oder an Dritte, die Datenverwaltungsdienste für unsere Website ausführen. Diese Dritten haben zugestimmt, alle Daten aufzubewahren aus Umfragen vertraulich. ",
        section6Header: "Wie wir die gesammelten Informationen verwenden",
        section6Para1: "Wir verwenden die Informationen, die wir sammeln, für Dinge wie:",
        section6Bullet1: "Optimierung der Leistung und Benutzererfahrung unserer Websites",
        section6Bullet2: "Betrieb, Bewertung und Verbesserung unseres Geschäfts",
        section6Bullet3: "Erfüllung von Bestellungen und Anfragen nach Produkten, Dienstleistungen oder Informationen",
        section6Bullet4: "Retouren und Umtausch bearbeiten",
        section6Bullet5: "Online-Bestellungen verfolgen und bestätigen",
        section6Bullet6: "Liefern oder Installieren von Produkten",
        section6Bullet7: "Marketing- und Werbeprodukte und -dienstleistungen, einschließlich der Identifizierung von Ihnen auf unseren Websites und der Anpassung von Anzeigen und Angeboten an Sie (sowohl auf unseren Websites als auch auf anderen Websites) basierend auf Ihren Interaktionen mit uns in unseren Filialen und online",
        section6Bullet8: "Durchführung von Forschung und Analyse",
        section6Bullet9: "Kommunikation mit Ihnen über Ihr Konto, besondere Ereignisse und Umfragen",
        section6Bullet10: "Einrichten und Verwalten Ihrer Konten bei uns",
        section6Para2: "Wir können diese Informationen mit allen anderen Informationen kombinieren, die wir über Sie haben, einschließlich, wenn Sie ein Healthierge-Kunde sind, jeder PHI, die wir über Sie haben. Wenn wir diese Informationen mit Ihrer PHI kombinieren, werden wir alle diese Informationen als behandeln PHI und wird diese Informationen nur gemäß unserer Mitteilung über Datenschutzpraktiken verwenden oder offenlegen. "
    },
    {
        id: "FR",
        spinner: "Mise à jour",
        agree: "J'accepte et j'accepte la politique de confidentialité",
        header: "Politique de confidentialité",
        section1Para1: "Cette politique a été mise à jour pour la dernière fois le 20 décembre 2020.",
        section2Header: "Politique de confidentialité Web",
        section2Para1: "Nous prenons votre vie privée au sérieux, et nous voulons que vous sachiez comment nous collectons, utilisons, partageons et protégeons vos informations. En plus de cette politique de confidentialité, les utilisateurs de Healthierge devraient consulter les conditions d'utilisation du site ainsi que tout produit spécifique. conditions générales qui s'appliquent. Vous pouvez consulter les politiques spécifiquement liées aux informations sur les patients (informations de santé protégées ou PHI) soumises via le portail Healthierge Online Services. Cette politique s'applique à healthierge.com, my.healthierge.com et à d'autres sites Web sous le contrôle de Healthierge . D'autres propriétés en ligne de Healthierge peuvent avoir leurs propres politiques de confidentialité qui s'appliquent à ces sites. Vous devriez consulter ces politiques de confidentialité en relation avec votre utilisation de ces sites. ",
        section3Header: "Quelles informations nous collectons",
        section3Para1: "Informations que vous nous donnez: Nous respectons le droit à la vie privée de tous les visiteurs des sites Healthierge. Nous recevons et stockons les informations que vous entrez sur nos sites ou que vous nous communiquez de toute autre manière, comme le nom, l'adresse e-mail et le numéro de téléphone. Cela inclut les informations que vous soumettez sur des formulaires, tels que les formulaires de demande de rendez-vous. Certains formulaires collectent des informations sensibles, telles que des informations sur la santé, nécessaires pour que nous puissions vous fournir nos services. ",
        section3Para2: "Informations que nous collectons automatiquement: lorsque vous interagissez avec nos sites, certaines informations relatives à votre utilisation de nos sites sont automatiquement collectées. Ces informations incluent des informations informatiques et de connexion, telles que des statistiques sur vos pages vues, le trafic vers et depuis nos sites, URL de référence, données d'annonces, votre adresse IP et identifiants d'appareil. Ces informations peuvent également inclure l'historique de vos transactions et les informations de votre journal Web, la manière dont vous recherchez nos sites, les sites Web sur lesquels vous cliquez sur nos sites ou les e-mails, si et quand vous ouvrez nos e-mails et vos activités de navigation sur d'autres sites Web. ",
        section3Para3: "Une grande partie de ces informations est collectée via des cookies, des balises Web et d'autres technologies de suivi, ainsi que via votre navigateur Web ou appareil (par exemple, adresse IP, adresse MAC, version du navigateur, etc.).",
        section4Header: "Communications par e-mail, newsletter et services associés",
        section4Para1: "Healthierge vous offre la possibilité de recevoir des communications de notre part ou de tiers. Vous pouvez vous inscrire à une newsletter gratuite Healthierge par e-mail. Vous pouvez vous désinscrire de cette newsletter à tout moment.",
        section4Para2: "Les communications par e-mail que vous nous envoyez via les liens e-mail de notre site peuvent être partagées avec un représentant du service client, un employé, un expert médical, des institutions médicales avec lesquelles nous collaborons ou un agent qui est le plus en mesure de répondre à votre demande. Nous mettons tout en œuvre pour répondre en temps opportun une fois les communications reçues. Une fois que nous avons répondu à votre communication, elle est supprimée ou archivée, selon la nature de la demande. ",
        section4Para3: "La fonctionnalité e-mail de notre site ne fournit pas un moyen de communication totalement sécurisé et confidentiel. Il est possible que votre communication par e-mail puisse être consultée ou consultée par un autre internaute lorsqu'elle est en transit vers nous. Si vous souhaitez garder votre communication privée , n'utilisez pas notre adresse e-mail. ",
        section4Para4: "Vous pouvez décider à un moment donné que vous ne souhaitez plus recevoir de communications de notre site. Pour ne plus recevoir de communications, vous pouvez simplement vous connecter à notre Centre de services en ligne et désélectionner l'option associée.",
        section5Header: "Enquêtes",
        section5Para1: "Nous sondons occasionnellement les visiteurs de notre site. Les informations issues de ces enquêtes sont utilisées sous une forme agrégée et anonymisée pour nous aider à comprendre les besoins de nos visiteurs afin que nous puissions améliorer notre site. Les informations peuvent être partagées avec des tiers. avec qui nous entretenons une relation commerciale. En règle générale, nous ne demandons pas d'informations dans le cadre d'enquêtes permettant de vous identifier personnellement; si nous demandons des coordonnées pour un suivi, vous pouvez refuser de les fournir. Si les répondants au sondage fournissent des informations personnelles (telles que une adresse e-mail) dans une enquête, elle est partagée uniquement avec les personnes qui ont besoin de la voir pour répondre à la question ou à la demande, ou avec des tiers qui fournissent des services de gestion de données pour notre site. Ces tiers ont accepté de conserver toutes les données à partir d'enquêtes confidentielles. ",
        section6Header: "Comment nous utilisons les informations que nous collectons",
        section6Para1: "Nous utilisons les informations que nous collectons pour des choses telles que:",
        section6Bullet1: "Optimiser les performances et l'expérience utilisateur de nos sites",
        section6Bullet2: "Exploiter, évaluer et améliorer notre activité",
        section6Bullet3: "Traitement des commandes et des demandes de produits, services ou informations",
        section6Bullet4: "Traitement des retours et échanges",
        section6Bullet5: "Suivi et confirmation des commandes en ligne",
        section6Bullet6: "Livraison ou installation de produits",
        section6Bullet7: "Produits et services de marketing et de publicité, y compris vous identifier sur nos sites Web et personnaliser des publicités et des offres (à la fois sur nos sites Web et sur d'autres sites Web) en fonction de vos interactions avec nous dans nos magasins et en ligne",
        section6Bullet8: "Mener des recherches et des analyses",
        section6Bullet9: "Communiquer avec vous à propos de votre compte, des événements spéciaux et des enquêtes",
        section6Bullet10: "Créer et gérer vos comptes avec nous",
        section6Para2: "Nous pouvons combiner ces informations avec toute autre information que nous avons à votre sujet, y compris, si vous êtes un client Healthierge, toute PHI que nous avons à votre sujet. Si nous combinons ces informations avec votre PHI, nous traiterons toutes ces informations comme PHI, et n'utilisera ou ne divulguera ces informations que comme indiqué dans notre avis de pratiques de confidentialité. "
    },
    {
        id: "IT",
        spinner: "In aggiornamento",
        agree: "Accetto e accetto l'informativa sulla privacy",
        header: "Informativa sulla Privacy",
        section1Para1: "Questa politica è stata aggiornata l'ultima volta il 20 dicembre 2020.",
        section2Header: "Norme sulla privacy del Web",
        section2Para1: "Prendiamo sul serio la tua privacy e vogliamo che tu sappia come raccogliamo, usiamo, condividiamo e proteggiamo le tue informazioni. Oltre a questa politica sulla privacy, gli utenti di Healthierge dovrebbero consultare i termini di utilizzo del sito così come qualsiasi prodotto specifico termini e condizioni applicabili. È possibile rivedere le politiche specificamente correlate alle informazioni sui pazienti (informazioni sanitarie protette o PHI) inviate tramite il portale Healthierge Online Services. Questa politica si applica a healthierge.com, my.healthierge.com e altri siti Web sotto il controllo di Healthierge . Altre proprietà online di Healthierge potrebbero avere le proprie norme sulla privacy che si applicano a tali siti. È necessario rivedere tali norme sulla privacy in relazione al proprio utilizzo di tali siti. ",
        section3Header: "Quali informazioni raccogliamo",
        section3Para1: "Informazioni che ci fornite: rispettiamo il diritto alla privacy di tutti i visitatori dei siti Healthierge. Riceviamo e memorizziamo le informazioni che inserite nei nostri siti o che ci fornite in qualsiasi altro modo, come nome, indirizzo e-mail e numero di telefono. Ciò include le informazioni che invii sui moduli, come i moduli di richiesta di appuntamento. Alcuni moduli raccolgono informazioni sensibili, come le informazioni sanitarie, necessarie per fornirti i nostri servizi. ",
        section3Para2: "Informazioni che raccogliamo automaticamente: quando interagisci con i nostri siti, vengono raccolte automaticamente determinate informazioni sull'utilizzo dei nostri siti. Queste informazioni includono informazioni sul computer e sulla connessione, come le statistiche sulle visualizzazioni delle tue pagine, il traffico da e verso i nostri siti, URL di riferimento, dati sugli annunci, indirizzo IP e identificatori del dispositivo. Queste informazioni possono anche includere la cronologia delle transazioni e le informazioni del registro Web, il modo in cui cerchi i nostri siti, i siti Web su cui fai clic dai nostri siti o e-mail, se e quando apri le nostre e-mail e le tue attività di navigazione su altri siti web. ",
        section3Para3: "La maggior parte di queste informazioni vengono raccolte tramite cookie, web beacon e altre tecnologie di tracciamento, nonché tramite il tuo browser web o dispositivo (ad es. indirizzo IP, indirizzo MAC, versione del browser, ecc.).",
        section4Header: "Comunicazioni e-mail, newsletter e servizi correlati",
        section4Para1: "Healthierge ti offre l'opportunità di ricevere comunicazioni da noi o da terzi. Puoi iscriverti a una newsletter gratuita di Healthierge. Puoi annullare l'iscrizione a questa newsletter in qualsiasi momento.",
        section4Para2: "Le comunicazioni e-mail che ci invii tramite i collegamenti e-mail sul nostro sito possono essere condivise con un rappresentante del servizio clienti, un dipendente, un esperto medico, istituzioni mediche con cui collaboriamo o un agente che è più in grado di rispondere alla tua richiesta. Facciamo ogni sforzo per rispondere in modo tempestivo una volta ricevute le comunicazioni. Una volta che abbiamo risposto alla tua comunicazione, questa viene eliminata o archiviata, a seconda della natura della richiesta. ",
        section4Para3: "La funzionalità di posta elettronica sul nostro sito non fornisce un mezzo di comunicazione completamente sicuro e riservato. È possibile che la tua comunicazione di posta elettronica possa essere consultata o visualizzata da un altro utente Internet durante il transito verso di noi. Se desideri mantenere la tua comunicazione privata , non utilizzare la nostra email. ",
        section4Para4: "A un certo punto potresti decidere di non voler più ricevere comunicazioni dal nostro sito. Per interrompere la ricezione di comunicazioni, puoi semplicemente accedere al nostro Centro servizi in linea e deselezionare l'opzione correlata.",
        section5Header: "Sondaggi",
        section5Para1: "Occasionalmente intervistiamo i visitatori del nostro sito. Le informazioni di questi sondaggi vengono utilizzate in forma aggregata e anonimizzata per aiutarci a comprendere le esigenze dei nostri visitatori in modo da poter migliorare il nostro sito. Le informazioni possono essere condivise con terze parti con i quali abbiamo una relazione d'affari. In genere non chiediamo informazioni nei sondaggi che potrebbero identificarti personalmente; se richiediamo le informazioni di contatto per il follow-up, potresti rifiutarti di fornirle. Se gli intervistati forniscono informazioni personali (come un indirizzo e-mail) in un sondaggio, viene condiviso solo con quelle persone che hanno bisogno di vederlo per rispondere alla domanda o alla richiesta, o con terze parti che eseguono servizi di gestione dei dati per il nostro sito. Tali terze parti hanno accettato di conservare tutti i dati da sondaggi riservati. ",
        section6Header: "Come utilizziamo le informazioni che raccogliamo",
        section6Para1: "Usiamo le informazioni che raccogliamo per cose come:",
        section6Bullet1: "Ottimizzazione delle prestazioni e dell'esperienza utente dei nostri siti",
        section6Bullet2: "Gestire, valutare e migliorare la nostra attività",
        section6Bullet3: "Evasione di ordini e richieste di prodotti, servizi o informazioni",
        section6Bullet4: "Elaborazione di resi e cambi",
        section6Bullet5: "Monitoraggio e conferma degli ordini online",
        section6Bullet6: "Consegna o installazione di prodotti",
        section6Bullet7: "Marketing e pubblicità di prodotti e servizi, inclusa l'identificazione dell'utente sui nostri siti Web e la personalizzazione di annunci e offerte (sia sui nostri siti Web che su altri siti Web) in base alle interazioni con noi nei nostri negozi e online",
        section6Bullet8: "Condurre ricerche e analisi",
        section6Bullet9: "Comunicare con te sul tuo account, eventi speciali e sondaggi",
        section6Bullet10: "Creazione e gestione dei tuoi account con noi",
        section6Para2: "Possiamo combinare queste informazioni con qualsiasi altra informazione che abbiamo su di te, inclusi, se sei un cliente Healthierge, qualsiasi PHI che abbiamo su di te. Se combiniamo queste informazioni con il tuo PHI, tratteremo tutte queste informazioni come PHI e utilizzerà o divulgherà tali informazioni solo come stabilito nella nostra informativa sulle pratiche sulla privacy. "
    },
    {
        id: "ES",
        spinner: "Actualizando",
        agree: "Estoy de acuerdo y acepto la política de privacidad.",
        header: "Política de Privacidad",
        section1Para1: "Esta política se actualizó por última vez el 20 de diciembre de 2020.",
        section2Header: "Política de privacidad web",
        section2Para1: "Nos tomamos en serio su privacidad y queremos que sepa cómo recopilamos, usamos, compartimos y protegemos su información. Además de esta política de privacidad, los usuarios de Healthierge deben consultar los términos de uso del sitio, así como cualquier producto específico términos y condiciones que se aplican. Puede revisar las políticas específicamente relacionadas con la información del paciente (información de salud protegida o PHI) enviada a través del portal de Servicios en línea de Healthierge. Esta política se aplica a healthierge.com, my.healthierge.com y otras ubicaciones web bajo el control de Healthierge . Otras propiedades en línea de Healthierge pueden tener sus propias políticas de privacidad que se aplican a esos sitios. Debe revisar esas políticas de privacidad en relación con su uso de esos sitios. ",
        section3Header: "Qué información recopilamos",
        section3Para1: "Información que nos proporciona: Respetamos el derecho a la privacidad de todos los visitantes de los sitios de Healthierge. Recibimos y almacenamos la información que ingresa en nuestros sitios o nos proporciona de cualquier otra manera, como el nombre, la dirección de correo electrónico y el número de teléfono. Esto incluye la información que envía en formularios, como los formularios de solicitud de cita. Algunos formularios recopilan información confidencial, como información de salud, necesaria para que podamos brindarle nuestros servicios. ",
        section3Para2: "Información que recopilamos automáticamente: cuando interactúa con nuestros sitios, se recopila automáticamente cierta información sobre su uso de nuestros sitios. Esta información incluye información de la computadora y la conexión, como estadísticas sobre las visitas a su página, el tráfico hacia y desde nuestros sitios, URL de referencia, datos de anuncios, su dirección IP e identificadores de dispositivo. Esta información también puede incluir su historial de transacciones y su información de registro web, cómo busca nuestros sitios, los sitios web en los que hace clic desde nuestros sitios o correos electrónicos, si y cuándo abre nuestros correos electrónicos y sus actividades de navegación en otros sitios web. ",
        section3Para3: "Gran parte de esta información se recopila a través de cookies, balizas web y otras tecnologías de seguimiento, así como a través de su navegador web o dispositivo (por ejemplo, dirección IP, dirección MAC, versión del navegador, etc.)",
        section4Header: "Comunicaciones por correo electrónico, boletín informativo y servicios relacionados",
        section4Para1: "Healthierge le brinda la oportunidad de recibir comunicaciones de nosotros o de terceros. Puede suscribirse a un boletín electrónico gratuito de Healthierge. Puede darse de baja de este boletín en cualquier momento.",
        section4Para2: "Las comunicaciones por correo electrónico que nos envíe a través de los enlaces de correo electrónico en nuestro sitio pueden compartirse con un representante de servicio al cliente, un empleado, un experto médico, las instituciones médicas con las que colaboramos o un agente que sea más capaz de atender su consulta. Hacemos todo lo posible para responder de manera oportuna una vez que se reciben las comunicaciones. Una vez que hayamos respondido a su comunicación, se descarta o se archiva, según la naturaleza de la consulta. ",
        section4Para3: "La funcionalidad de correo electrónico en nuestro sitio no proporciona un medio de comunicación completamente seguro y confidencial. Es posible que otro usuario de Internet pueda acceder o ver su comunicación por correo electrónico mientras se encuentra en tránsito hacia nosotros. Si desea mantener su comunicación en privado , no utilice nuestro correo electrónico. ",
        section4Para4: "Puede decidir en algún momento que ya no desea recibir comunicaciones de nuestro sitio. Para dejar de recibir comunicaciones, simplemente puede iniciar sesión en nuestro Centro de servicios en línea y anular la selección de la opción relacionada.",
        section5Header: "Encuestas",
        section5Para1: "Ocasionalmente encuestamos a los visitantes de nuestro sitio. La información de estas encuestas se utiliza en forma agregada y anónima para ayudarnos a comprender las necesidades de nuestros visitantes para que podamos mejorar nuestro sitio. La información puede ser compartida con terceros con quien tenemos una relación comercial. Por lo general, no solicitamos información en encuestas que lo identifique personalmente; si solicitamos información de contacto para seguimiento, puede negarse a proporcionarla. Si los encuestados brindan información personal (como una dirección de correo electrónico) en una encuesta, se comparte solo con aquellas personas que necesitan verla para responder a la pregunta o solicitud, o con terceros que realizan servicios de gestión de datos para nuestro sitio. Esos terceros han acordado conservar todos los datos de encuestas confidenciales. ",
        section6Header: "Cómo utilizamos la información que recopilamos",
        section6Para1: "Usamos la información que recopilamos para cosas como:",
        section6Bullet1: "Optimización del rendimiento y la experiencia del usuario de nuestros sitios",
        section6Bullet2: "Operar, evaluar y mejorar nuestro negocio",
        section6Bullet3: "Cumplimiento de pedidos y solicitudes de productos, servicios o información",
        section6Bullet4: "Procesando devoluciones y cambios",
        section6Bullet5: "Seguimiento y confirmación de pedidos online",
        section6Bullet6: "Entrega o instalación de productos",
        section6Bullet7: "Productos y servicios de marketing y publicidad, incluida la identificación de usted en nuestros sitios web y la adaptación de anuncios y ofertas para usted (tanto en nuestros sitios web como en otros sitios web) en función de sus interacciones con nosotros en nuestras tiendas y en línea",
        section6Bullet8: "Realización de investigaciones y análisis",
        section6Bullet9: "Comunicándonos con usted sobre su cuenta, eventos especiales y encuestas",
        section6Bullet10: "Establecer y administrar sus cuentas con nosotros",
        section6Para2: "Podemos combinar esta información con cualquier otra información que tengamos sobre usted, incluso, si es un cliente de Healthierge, cualquier PHI que tengamos sobre usted. Si combinamos esta información con su PHI, trataremos toda esa información como PHI, y solo usará o divulgará esa información como se establece en nuestro aviso de prácticas de privacidad."
    },
    {
        id: "PT",
        spinner: "Atualizando",
        agree: "Eu concordo e aceito a política de privacidade",
        header: "Política de Privacidade",
        section1Para1: "Esta política foi atualizada pela última vez em 20 de dezembro de 2020.",
        section2Header: "Política de privacidade da web",
        section2Para1: "Levamos sua privacidade a sério e queremos que você saiba como coletamos, usamos, compartilhamos e protegemos suas informações. Além desta política de privacidade, os usuários do Healthierge devem consultar os termos de uso do site, bem como qualquer produto específico termos e condições aplicáveis. Você pode revisar as políticas especificamente relacionadas às informações do paciente (informações de saúde protegidas ou PHI) enviadas através do portal Healthierge Online Services. Esta política se aplica a healthierge.com, my.healthierge.com e outros locais da Web sob o controle do Healthierge . Outras propriedades online do Healthierge podem ter suas próprias políticas de privacidade que se aplicam a esses sites. Você deve revisar essas políticas de privacidade em conexão com o uso desses sites. ",
        section3Header: "Quais informações nós coletamos",
        section3Para1: "Informações que você nos fornece: Respeitamos o direito à privacidade de todos os visitantes dos sites Healthierge. Recebemos e armazenamos as informações que você insere em nossos sites ou nos fornece de qualquer outra forma, como nome, endereço de e-mail e número de telefone. Isso inclui informações que você envia em formulários, como formulários de solicitação de agendamento. Alguns formulários coletam informações confidenciais, como informações de saúde, necessárias para que possamos fornecer nossos serviços a você. ",
        section3Para2: "Informações que coletamos automaticamente: Quando você interage com nossos sites, certas informações sobre o uso de nossos sites são coletadas automaticamente. Essas informações incluem informações de computador e conexão, como estatísticas sobre visualizações de páginas, tráfego de e para nossos sites, URL de referência, dados de anúncio, seu endereço IP e identificadores de dispositivo. Essas informações também podem incluir seu histórico de transações e suas informações de log da web, como você pesquisa nossos sites, os sites em que clica em nossos sites ou e-mails, se e quando você abre nossos e-mails e suas atividades de navegação em outros sites. ",
        section3Para3: "Muitas dessas informações são coletadas por meio de cookies, web beacons e outras tecnologias de rastreamento, bem como por meio de seu navegador ou dispositivo (por exemplo, endereço IP, endereço MAC, versão do navegador, etc.).",
        section4Header: "Comunicações por e-mail, boletim informativo e serviços relacionados",
        section4Para1: "Healthierge lhe oferece a oportunidade de receber comunicações nossas ou de terceiros. Você pode se inscrever para receber um boletim informativo gratuito por e-mail do Healthierge. Você pode cancelar a assinatura deste boletim a qualquer momento.",
        section4Para2: "As comunicações por e-mail que você nos envia através dos links de e-mail em nosso site podem ser compartilhadas com um representante de atendimento ao cliente, funcionário, especialista médico, instituições médicas com as quais colaboramos ou agente que seja mais capaz de responder à sua consulta. para responder em tempo hábil assim que as comunicações forem recebidas. Assim que respondermos à sua comunicação, ela será descartada ou arquivada, dependendo da natureza da consulta. ",
        section4Para3: "A funcionalidade de e-mail em nosso site não fornece um meio de comunicação totalmente seguro e confidencial. É possível que sua comunicação por e-mail seja acessada ou visualizada por outro usuário da Internet enquanto estiver em trânsito para nós. Se você deseja manter sua comunicação privada , não use nosso e-mail. ",
        section4Para4: "Você pode decidir em algum momento que não deseja mais receber comunicações de nosso site. Para parar de receber comunicações, você pode simplesmente entrar em nosso Centro de Serviços Online e desmarcar a opção relacionada.",
        section5Header: "Pesquisas",
        section5Para1: "Ocasionalmente, fazemos pesquisas sobre os visitantes de nosso site. As informações dessas pesquisas são usadas de forma agregada e não identificada para nos ajudar a entender as necessidades de nossos visitantes para que possamos melhorar nosso site. As informações podem ser compartilhadas com terceiros com quem temos uma relação comercial. Geralmente não pedimos informações em pesquisas que possam identificá-lo pessoalmente; se solicitarmos informações de contato para acompanhamento, você pode recusar-se a fornecê-las. Se os respondentes da pesquisa fornecerem informações pessoais (como um endereço de e-mail) em uma pesquisa, ele é compartilhado apenas com aquelas pessoas que precisam vê-lo para responder à pergunta ou solicitação, ou com terceiros que realizam serviços de gerenciamento de dados para o nosso site. Esses terceiros concordaram em manter todos os dados de pesquisas confidenciais. ",
        section6Header: "Como usamos as informações que coletamos",
        section6Para1: "Usamos as informações que coletamos para coisas como:",
        section6Bullet1: "Otimizando o desempenho e a experiência do usuário de nossos sites",
        section6Bullet2: "Operando, avaliando e melhorando nosso negócio",
        section6Bullet3: "Atendimento a pedidos e solicitações de produtos, serviços ou informações",
        section6Bullet4: "Processando devoluções e trocas",
        section6Bullet5: "Rastreando e confirmando pedidos online",
        section6Bullet6: "Entrega ou instalação de produtos",
        section6Bullet7: "Produtos e serviços de marketing e publicidade, incluindo identificá-lo em nossos sites e personalizar anúncios e ofertas para você (tanto em nossos sites como em outros sites) com base em suas interações conosco em nossas lojas e online",
        section6Bullet8: "Conduzindo pesquisas e análises",
        section6Bullet9: "Comunicar-se com você sobre sua conta, eventos especiais e pesquisas",
        section6Bullet10: "Estabelecendo e gerenciando suas contas conosco",
        section6Para2: "Podemos combinar essas informações com quaisquer outras informações que temos sobre você, incluindo, se você for um cliente Healthierge, qualquer PHI que tenhamos sobre você. Se combinarmos essas informações com o seu PHI, trataremos todas essas informações como PHI e só usará ou divulgará essas informações conforme estabelecido em nosso aviso de práticas de privacidade. "
    },
    {
        id: "RU",
        spinner: "Обновление",
        agree: "Я согласен и принимаю политику конфиденциальности",
        header: "Политика конфиденциальности",
        section1Para1: "Последний раз политика обновлялась 20 декабря 2020 г.",
        section2Header: "Политика конфиденциальности в Интернете",
        section2Para1: "Мы серьезно относимся к вашей конфиденциальности и хотим, чтобы вы знали, как мы собираем, используем, передаем и защищаем вашу информацию. В дополнение к этой политике конфиденциальности пользователи Healthierge должны ознакомиться с условиями использования сайта, а также с любыми конкретными продуктами действующие положения и условия. Вы можете ознакомиться с политиками, конкретно касающимися информации о пациентах (защищенной медицинской информации или PHI), отправленной через портал Healthierge Online Services. Эта политика применяется к healthierge.com, my.healthierge.com и другим веб-сайтам, находящимся под контролем Healthierge. . Другие онлайн-ресурсы Healthierge могут иметь свои собственные политики конфиденциальности, применимые к этим сайтам. Вам следует ознакомиться с этими политиками конфиденциальности в связи с использованием вами этих сайтов. ",
        section3Header: "Какую информацию мы собираем",
        section3Para1: "Информация, которую вы нам предоставляете: Мы уважаем право на неприкосновенность частной жизни всех посетителей сайтов Healthierge. Мы получаем и храним информацию, которую вы вводите на наших сайтах или предоставляете нам любым другим способом, такую ​​как имя, адрес электронной почты и номер телефона. Сюда входит информация, которую вы отправляете в формах, таких как формы запроса на прием. Некоторые формы собирают конфиденциальную информацию, такую ​​как медицинская информация, которая необходима нам для предоставления вам наших услуг. ",
        section3Para2: "Информация, которую мы собираем автоматически: когда вы взаимодействуете с нашими сайтами, определенная информация об использовании вами наших сайтов собирается автоматически. Эта информация включает информацию о компьютере и подключении, такую ​​как статистика просмотров ваших страниц, посещаемость наших сайтов и с них, URL-адрес перехода, данные рекламы, ваш IP-адрес и идентификаторы устройства. Эта информация также может включать вашу историю транзакций и информацию вашего веб-журнала, то, как вы выполняете поиск на наших сайтах, веб-сайты, на которые вы переходите с наших сайтов или по электронной почте, независимо от того, когда и когда вы открываете наши электронные письма и просматриваете другие веб-сайты. ",
        section3Para3: "Большая часть этой информации собирается с помощью файлов cookie, веб-маяков и других технологий отслеживания, а также через ваш веб-браузер или устройство (например, IP-адрес, MAC-адрес, версию браузера и т. д.).",
        section4Header: "Электронная почта, информационные бюллетени и сопутствующие услуги",
        section4Para1: "Healthierge предоставляет вам возможность получать сообщения от нас или третьих лиц. Вы можете подписаться на бесплатную рассылку новостей Healthierge по электронной почте. Вы можете отказаться от подписки на эту рассылку в любое время.",
        section4Para2: "Электронные сообщения, которые вы отправляете нам по ссылкам электронной почты на нашем сайте, могут быть переданы представителю службы поддержки клиентов, сотруднику, медицинскому эксперту, медицинским учреждениям, с которыми мы сотрудничаем, или агенту, который лучше всего сможет ответить на ваш запрос. Мы прилагаем все усилия своевременно отвечать после получения сообщений. После того, как мы ответим на ваше сообщение, оно удаляется или архивируется, в зависимости от характера запроса. ",
        section4Para3: "Функциональность электронной почты на нашем сайте не обеспечивает полностью безопасных и конфиденциальных средств связи. Возможно, ваше сообщение электронной почты может быть доступно или просмотрено другим пользователем Интернета во время передачи к нам. Если вы хотите сохранить конфиденциальность вашего сообщения , не используйте нашу электронную почту. ",
        section4Para4: "В какой-то момент вы можете решить, что больше не хотите получать сообщения с нашего сайта. Чтобы прекратить получать сообщения, вы можете просто войти в наш Центр онлайн-услуг и отменить выбор соответствующей опции.",
        section5Header: "Обзоры",
        section5Para1: "Мы время от времени опрашиваем посетителей нашего сайта. Информация из этих опросов используется в агрегированной, обезличенной форме, чтобы помочь нам понять потребности наших посетителей, чтобы мы могли улучшить наш сайт. Информация может быть передана третьим лицам с кем у нас есть деловые отношения. Обычно мы не запрашиваем информацию в опросах, которая могла бы идентифицировать вас лично; если мы все же запрашиваем контактную информацию для последующих действий, вы можете отказаться ее предоставить. Если респонденты опроса предоставляют личную информацию (например, адрес электронной почты) в опросе, он передается только тем людям, которым он нужен для ответа на вопрос или запрос, или третьим лицам, которые предоставляют услуги управления данными для нашего сайта. Эти третьи стороны согласились хранить все данные из опросов конфиденциально. ",
        section6Header: "Как мы используем собранную информацию",
        section6Para1: "Мы используем информацию, которую собираем, для таких вещей, как:",
        section6Bullet1: "Оптимизация производительности и удобства пользователей наших сайтов",
        section6Bullet2: "Управление, оценка и улучшение нашего бизнеса",
        section6Bullet3: "Выполнение заказов и запросов на товары, услуги или информацию",
        section6Bullet4: "Обработка возвратов и обменов",
        section6Bullet5: "Отслеживание и подтверждение онлайн-заказов",
        section6Bullet6: "Доставка или установка продуктов",
        section6Bullet7: "Маркетинговые и рекламные продукты и услуги, включая идентификацию вас на наших веб-сайтах и ​​адаптацию рекламных объявлений и предложений к вам (как на наших веб-сайтах, так и на других веб-сайтах) на основе вашего взаимодействия с нами в наших магазинах и в Интернете",
        section6Bullet8: "Проведение исследований и анализа",
        section6Bullet9: "Общение с вами о вашей учетной записи, специальных мероприятиях и опросах",
        section6Bullet10: "Создание и управление вашими счетами у нас",
        section6Para2: "Мы можем объединить эту информацию с любой другой имеющейся у нас информацией о вас, включая, если вы являетесь клиентом Healthierge, любую имеющуюся у нас PHI о вас. Если мы объединим эту информацию с вашей PHI, мы будем рассматривать всю эту информацию как PHI, и будет использовать или раскрывать эту информацию только в соответствии с нашим уведомлением о политике конфиденциальности."
    },
    {
        id: "BG",
        spinner: "Актуализиране",
        agree: "Съгласен съм и приемам политиката за поверителност",
        header: "Политика за поверителност",
        section1Para1: "Тази политика е актуализирана последно на 20 декември 2020 г.",
        section2Header: "Политика за поверителност в мрежата",
        section2Para1: "Ние приемаме вашата поверителност на сериозно и искаме да знаете как събираме, използваме, споделяме и защитаваме вашата информация. В допълнение към тази политика за поверителност потребителите на Healthierge трябва да се консултират с условията за ползване на сайта, както и с конкретни продукти Общи условия, които се прилагат. Можете да прегледате политики, специално свързани с информация за пациента (защитена здравна информация или PHI), предоставена чрез портала Healthierge Online Services. Тази политика се отнася за healthierge.com, my.healthierge.com и други уеб местоположения под контрола на Healthierge . Другите онлайн собствености на Healthierge може да имат свои собствени политики за поверителност, които се прилагат за тези сайтове. Трябва да прегледате тези политики за поверителност във връзка с използването на тези сайтове. ",
        section3Header: "Каква информация събираме",
        section3Para1: "Информация, която ни предоставяте: Ние уважаваме правото на неприкосновеност на личния живот на всички посетители на сайтовете Healthierge. Получаваме и съхраняваме информация, която въвеждате на нашите сайтове или ни предоставяте по друг начин, като име, имейл адрес и телефонен номер. Това включва информация, която изпращате във формуляри, като например формуляри за искане за среща. Някои формуляри събират чувствителна информация, като здравна информация, необходима за да ви предоставим нашите услуги. ",
        section3Para2: "Информация, която събираме автоматично: Когато взаимодействате с нашите сайтове, автоматично се събира определена информация за използването на нашите сайтове. Тази информация включва информация за компютъра и връзките, като статистически данни за показванията на вашата страница, трафика към и от нашите сайтове, URL за препращане, рекламни данни, вашия IP адрес и идентификатори на устройства. Тази информация може също да включва историята на вашите транзакции и информацията за вашия уеб дневник, как търсите нашите сайтове, уебсайтовете, върху които кликвате от нашите сайтове или имейли, независимо дали и кога отваряте нашите имейли и вашите сърфиращи дейности в други уебсайтове. ",
        section3Para3: "Голяма част от тази информация се събира чрез бисквитки, уеб маяци и други технологии за проследяване, както и чрез вашия уеб браузър или устройство (напр. IP адрес, MAC адрес, версия на браузъра и т.н.).",
        section4Header: "Комуникация по имейл, бюлетин и свързани услуги",
        section4Para1: "Healthierge ви предоставя възможност да получавате съобщения от нас или трети страни. Можете да се регистрирате за безплатен бюлетин по имейл на Healthierge. Можете да се отпишете от този бюлетин по всяко време.",
        section4Para2: "Комуникациите по имейл, които ни изпращате чрез имейл връзките на нашия сайт, могат да бъдат споделени с представител на обслужване на клиенти, служител, медицински експерт, лечебни заведения, в които си сътрудничим, или агент, който е най-способен да отговори на вашето запитване. Ние полагаме всички усилия да отговорим своевременно след получаване на съобщенията. След като отговорим на вашето съобщение, то се изхвърля или архивира, в зависимост от естеството на запитването. ",
        section4Para3: "Функционалността на имейлите на нашия сайт не осигурява напълно сигурно и поверително средство за комуникация. Възможно е вашият имейл комуникация да бъде достъпен или разгледан от друг потребител на интернет, докато е в транспорт до нас. Ако искате да запазите вашата комуникация частна , не използвайте нашия имейл. ",
        section4Para4: "В даден момент можете да решите, че вече не желаете да получавате съобщения от нашия сайт. За да спрете да получавате съобщения, можете просто да влезете в нашия Център за онлайн услуги и да отмените избора на свързаната опция.",
        section5Header: "Проучвания",
        section5Para1: "От време на време анкетираме посетителите на нашия сайт. Информацията от тези проучвания се използва в обобщена, неидентифицирана форма, за да ни помогне да разберем нуждите на нашите посетители, за да можем да подобрим нашия сайт. Информацията може да бъде споделена с трети страни с които имаме бизнес отношения. Обикновено не искаме информация в анкети, които биха ви идентифицирали лично; ако поискаме информация за контакт за проследяване, може да откажете да я предоставите. Ако респондентите предоставят лична информация (като например имейл адрес) в проучване, той се споделя само с хората, които трябва да го видят, за да отговорят на въпроса или искането, или с трети страни, които извършват услуги за управление на данни за нашия сайт. Тези трети страни са се съгласили да пазят всички данни от поверителни проучвания. ",
        section6Header: "Как използваме информацията, която събираме",
        section6Para1: "Използваме събраната информация за неща като:",
        section6Bullet1: "Оптимизиране на работата и потребителския опит на нашите сайтове",
        section6Bullet2: "Работа, оценка и подобряване на нашия бизнес",
        section6Bullet3: "Изпълнение на поръчки и заявки за продукти, услуги или информация",
        section6Bullet4: "Обработка на връщания и обмени",
        section6Bullet5: "Проследяване и потвърждаване на онлайн поръчки",
        section6Bullet6: "Доставка или инсталиране на продукти",
        section6Bullet7: "Маркетинг и рекламни продукти и услуги, включително идентифициране на вас на нашите уебсайтове и приспособяване на реклами и оферти към вас (както на нашите уебсайтове, така и на други уебсайтове) въз основа на вашето взаимодействие с нас в нашите магазини и онлайн",
        section6Bullet8: "Провеждане на изследвания и анализи",
        section6Bullet9: "Комуникация с вас за вашия акаунт, специални събития и анкети",
        section6Bullet10: "Създаване и управление на Вашите акаунти при нас",
        section6Para2: "Ние можем да комбинираме тази информация с всякаква друга информация, която имаме за вас, включително, ако сте клиент на Healthierge, всяка PHI, която имаме за вас. Ако комбинираме тази информация с вашата PHI, ние ще третираме цялата тази информация като PHI и ще използва или разкрива само тази информация, както е посочено в нашето известие за практики за поверителност. "    },
    {
        id: "GR",
        spinner: "Ενημέρωση",
        agree: "Συμφωνώ και αποδέχομαι την πολιτική απορρήτου",
        header: "Πολιτική απορρήτου",
        section1Para1: "Αυτή η πολιτική ενημερώθηκε τελευταία φορά στις 20 Δεκεμβρίου 2020.",
        section2Header: "Πολιτική απορρήτου Ιστού",
        section2Para1: "Λαμβάνουμε σοβαρά υπόψη το απόρρητό σας και θέλουμε να γνωρίζετε πώς συλλέγουμε, χρησιμοποιούμε, κοινοποιούμε και προστατεύουμε τις πληροφορίες σας. Εκτός από αυτήν την πολιτική απορρήτου, οι χρήστες του Healthierge θα πρέπει να συμβουλεύονται τους όρους χρήσης του ιστότοπου καθώς και οποιοδήποτε συγκεκριμένο προϊόν Όροι και προϋποθέσεις που ισχύουν. Μπορείτε να αναθεωρήσετε πολιτικές που σχετίζονται συγκεκριμένα με πληροφορίες ασθενούς (προστατευόμενες πληροφορίες υγείας ή PHI) που υποβάλλονται μέσω της πύλης Healthierge Online Services. Αυτή η πολιτική ισχύει για τα healthierge.com, my.healthierge.com και άλλες τοποθεσίες Web υπό τον έλεγχο της Healthierge . Άλλες διαδικτυακές ιδιότητες του Healthierge ενδέχεται να έχουν τις δικές τους πολιτικές απορρήτου που ισχύουν για αυτούς τους ιστότοπους. Θα πρέπει να ελέγξετε αυτές τις πολιτικές απορρήτου σε σχέση με τη χρήση αυτών των ιστότοπων. ",
        section3Header: "Τι πληροφορίες συλλέγουμε",
        section3Para1: "Πληροφορίες που μας δίνετε: Σεβόμαστε το δικαίωμα στην ιδιωτικότητα όλων των επισκεπτών των ιστότοπων Healthierge. Λαμβάνουμε και αποθηκεύουμε τις πληροφορίες που εισάγετε στους ιστότοπούς μας ή μας δίνουν με οποιονδήποτε άλλο τρόπο, όπως όνομα, διεύθυνση email και αριθμό τηλεφώνου. Αυτό περιλαμβάνει πληροφορίες που υποβάλλετε σε έντυπα, όπως έντυπα αιτήσεων ραντεβού. Ορισμένες φόρμες συλλέγουν ευαίσθητες πληροφορίες, όπως πληροφορίες υγείας, απαραίτητες για να παρέχουμε τις υπηρεσίες μας σε εσάς. ",
        section3Para2: "Πληροφορίες που συλλέγουμε αυτόματα: Όταν αλληλεπιδράτε με τους ιστότοπούς μας, συλλέγονται αυτόματα ορισμένες πληροφορίες σχετικά με τη χρήση των ιστότοπών μας. Αυτές οι πληροφορίες περιλαμβάνουν πληροφορίες υπολογιστή και σύνδεσης, όπως στατιστικά στοιχεία σχετικά με τις προβολές της σελίδας σας, την κίνηση προς και από τους ιστότοπούς μας, διεύθυνση URL παραπομπής, δεδομένα διαφημίσεων, διεύθυνση IP και αναγνωριστικά συσκευών. Αυτές οι πληροφορίες μπορεί επίσης να περιλαμβάνουν το ιστορικό συναλλαγών σας και τις πληροφορίες καταγραφής ιστού, τον τρόπο με τον οποίο αναζητάτε τους ιστότοπούς μας, τους ιστότοπους στους οποίους κάνετε κλικ από τους ιστότοπους ή τα μηνύματα ηλεκτρονικού ταχυδρομείου μας, είτε και πότε ανοίγετε τα email μας και τις δραστηριότητες περιήγησής σας σε άλλους ιστότοπους. ",
        section3Para3: "Πολλές από αυτές τις πληροφορίες συλλέγονται μέσω cookie, web beacons και άλλων τεχνολογιών παρακολούθησης, καθώς και μέσω του προγράμματος περιήγησης ιστού ή της συσκευής σας (π.χ. διεύθυνση IP, διεύθυνση MAC, έκδοση προγράμματος περιήγησης κ.λπ.).",
        section4Header: "Επικοινωνία μέσω email, ενημερωτικό δελτίο και συναφείς υπηρεσίες",
        section4Para1: "Το Healthierge σάς παρέχει την ευκαιρία να λαμβάνετε επικοινωνίες από εμάς ή τρίτα μέρη. Μπορείτε να εγγραφείτε σε ένα δωρεάν ενημερωτικό δελτίο email Healthierge. Μπορείτε να διαγραφείτε από αυτό το ενημερωτικό δελτίο ανά πάσα στιγμή.",
        section4Para2: "Οι επικοινωνίες μέσω email που μας στέλνετε μέσω των συνδέσμων email στον ιστότοπό μας ενδέχεται να κοινοποιούνται σε έναν αντιπρόσωπο εξυπηρέτησης πελατών, υπάλληλο, ιατρικό εμπειρογνώμονα, ιατρικά ιδρύματα που συνεργαζόμαστε ή με πράκτορα που είναι πιο ικανοί να αντιμετωπίσουν το ερώτημά σας. για να απαντήσετε εγκαίρως μετά τη λήψη των επικοινωνιών. Μόλις απαντήσουμε στην επικοινωνία σας, απορρίπτεται ή αρχειοθετείται, ανάλογα με τη φύση της έρευνας. ",
        section4Para3: "Η λειτουργικότητα του ηλεκτρονικού ταχυδρομείου στον ιστότοπό μας δεν παρέχει εντελώς ασφαλή και εμπιστευτικά μέσα επικοινωνίας. Είναι πιθανό η πρόσβαση στο email σας να είναι προσβάσιμη ή να προβληθεί από άλλον χρήστη του Διαδικτύου κατά τη μεταφορά σε εμάς. Εάν θέλετε να διατηρήσετε την επικοινωνία σας ιδιωτική , μην χρησιμοποιείτε το email μας. ",
        section4Para4: "Μπορείτε να αποφασίσετε κάποια στιγμή ότι δεν επιθυμείτε πλέον να λαμβάνετε επικοινωνίες από τον ιστότοπό μας. Για να σταματήσετε να λαμβάνετε επικοινωνίες, μπορείτε απλώς να συνδεθείτε στο Κέντρο Online Υπηρεσιών μας και να αποεπιλέξετε τη σχετική επιλογή.",
        section5Header: "Έρευνες",
        section5Para1: "Περιστασιακά ερευνούμε τους επισκέπτες στον ιστότοπό μας. Οι πληροφορίες από αυτές τις έρευνες χρησιμοποιούνται σε συγκεντρωτική, απο-προσδιορισμένη μορφή για να μας βοηθήσουν να κατανοήσουμε τις ανάγκες των επισκεπτών μας, ώστε να βελτιώσουμε τον ιστότοπό μας. Οι πληροφορίες ενδέχεται να κοινοποιηθούν σε τρίτους με τους οποίους έχουμε επιχειρηματική σχέση. Γενικά, δεν ζητάμε πληροφορίες σε έρευνες που θα σας ταυτοποιούν προσωπικά. εάν ζητήσουμε στοιχεία επικοινωνίας για παρακολούθηση, ενδέχεται να αρνηθείτε να τα παράσχετε. Εάν οι ερωτηθέντες της έρευνας παρέχουν προσωπικά στοιχεία (όπως μια διεύθυνση ηλεκτρονικού ταχυδρομείου) σε μια έρευνα, κοινοποιείται μόνο σε εκείνα τα άτομα που πρέπει να το δουν για να απαντήσουν στην ερώτηση ή το αίτημα ή με τρίτα μέρη που εκτελούν υπηρεσίες διαχείρισης δεδομένων για τον ιστότοπό μας. Αυτά τα τρίτα μέρη έχουν συμφωνήσει να διατηρήσουν όλα τα δεδομένα από έρευνες εμπιστευτικές. ",
        section6Header: "Πώς χρησιμοποιούμε τις πληροφορίες που συλλέγουμε",
        section6Para1: "Χρησιμοποιούμε τις πληροφορίες που συλλέγουμε για πράγματα όπως:",
        section6Bullet1: "Βελτιστοποίηση της απόδοσης και της εμπειρίας χρήστη των ιστότοπών μας",
        section6Bullet2: "Λειτουργία, αξιολόγηση και βελτίωση της επιχείρησής μας",
        section6Bullet3: "Εκπλήρωση παραγγελιών και αιτημάτων για προϊόντα, υπηρεσίες ή πληροφορίες",
        section6Bullet4: "Επεξεργασία επιστροφών και ανταλλαγών",
        section6Bullet5: "Παρακολούθηση και επιβεβαίωση ηλεκτρονικών παραγγελιών",
        section6Bullet6: "Παράδοση ή εγκατάσταση προϊόντων",
        section6Bullet7: "Μάρκετινγκ και διαφήμιση προϊόντων και υπηρεσιών, συμπεριλαμβανομένης της ταυτοποίησής σας στους ιστότοπούς μας και της προσαρμογής διαφημίσεων και προσφορών σε εσάς (τόσο στους ιστότοπους μας όσο και σε άλλους ιστότοπους) με βάση τις αλληλεπιδράσεις σας με εμάς στα καταστήματά μας και στο διαδίκτυο",
        section6Bullet8: "Διεξαγωγή έρευνας και ανάλυσης",
        section6Bullet9: "Επικοινωνία μαζί σας για τον λογαριασμό σας, ειδικές εκδηλώσεις και έρευνες",
        section6Bullet10: "Δημιουργία και διαχείριση των λογαριασμών σας μαζί μας",
        section6Para2: "Μπορούμε να συνδυάσουμε αυτές τις πληροφορίες με οποιεσδήποτε άλλες πληροφορίες έχουμε σχετικά με εσάς, συμπεριλαμβανομένων, εάν είστε πελάτης της Healthierge, οποιουδήποτε PHI έχουμε για εσάς. Εάν συνδυάσουμε αυτές τις πληροφορίες με το PHI σας, θα αντιμετωπίσουμε όλες αυτές τις πληροφορίες ως PHI, και θα χρησιμοποιήσει ή θα αποκαλύψει μόνο αυτές τις πληροφορίες όπως αναφέρονται στην ειδοποίηση σχετικά με τις πρακτικές απορρήτου."
    }
];

class PrivacyPolicy extends Component {
    componentDidMount() {
        const id = this.props.account.id;
        const text = "Privacy Policy: " + this.props.account.email;
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);

    }

    aggreeDisclaimer = () => {
        let id = this.props.account.id;
        let userDisclaimer = this.props.account.userDisclaimer;

        if (userDisclaimer === 0)
            userDisclaimer = 1;
        else
            userDisclaimer = 0;

        this.props.updateDisclaimer({ id, userDisclaimer });
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (  
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                {
                    this.props.account.status === fetchStates.fetching ?
                        <div className="Warning">
                            <Spinner animation="border" variant="secondary" /> {lItem.spinner}
                        </div> : 
                    <div className="custom-control custom-checkbox Warning">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="agree"
                            onChange={this.aggreeDisclaimer}
                            checked={this.props.account.userDisclaimer}
                        />
                        <label className="custom-control-label" htmlFor="agree">
                            {lItem.agree}
                        </label>
                    </div>
                }
                <div className="Page">
                    <div className="PageText">
                        {lItem.section1Para1}
                    </div>
                    <div className="PageSection">
                        {lItem.section2Header}
                    </div>
                    <div className="PageText">
                        {lItem.section2Para1}
                    </div>
                    <div className="PageSection">
                        {lItem.section3Header}
                    </div>
                    <div className="PageText">
                        {lItem.section3Para1}
                    </div>
                    <div className="PageText">
                        {lItem.section3Para2}
                    </div>
                    <div className="PageText">
                        {lItem.section3Para3}
                    </div>
                    <div className="PageSection">
                        {lItem.section4Header}
                    </div>
                    <div className="PageText">
                        {lItem.section4Para1}
                    </div>
                    <div className="PageText">
                        {lItem.section4Para2}
                    </div>
                    <div className="PageText">
                        {lItem.section4Para3}
                    </div>
                    <div className="PageText">
                        {lItem.section4Para4}
                    </div>
                    <div className="PageSection">
                        {lItem.section5Header}
                    </div>
                    <div className="PageText">
                        {lItem.section5Para1}
                    </div>
                    <div className="PageSection">
                        {lItem.section6Header}
                    </div>
                    <div className="PageText">
                        {lItem.section6Para1}
                    </div>
                    <ul className="PageBullet">
                        <li>{lItem.section6Bullet1}</li>
                        <li>{lItem.section6Bullet2}</li>
                        <li>{lItem.section6Bullet3}</li>
                        <li>{lItem.section6Bullet4}</li>
                        <li>{lItem.section6Bullet5}</li>
                        <li>{lItem.section6Bullet6}</li>
                        <li>{lItem.section6Bullet7}</li>
                        <li>{lItem.section6Bullet8}</li>
                        <li>{lItem.section6Bullet9}</li>
                        <li>{lItem.section6Bullet10}</li>
                    </ul>
                    <div className="PageText">
                        {lItem.section6Para2}
                    </div>
                </div>
                {
                    this.props.account.status === fetchStates.fetching ?
                        <div className="Warning">
                            <Spinner animation="border" variant="secondary" /> {lItem.spinner}
                        </div> : 
                    <div className="custom-control custom-checkbox Warning">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="agree"
                            onChange={this.aggreeDisclaimer}
                            checked={this.props.account.userDisclaimer}
                        />
                        <label className="custom-control-label" htmlFor="agree">
                            {lItem.agree}
                        </label>
                    </div>
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { updateDisclaimer, invokeLogger }
)(PrivacyPolicy);