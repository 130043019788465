import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import queryString from 'query-string';
import { signup,
         login,
         activateAccount,
         forgottenPassword } from '../actions/account';
import fetchStates from '../reducers/fetchStates';
import HeaderSub from './HeaderSub';

const lList = [
    {
        id: "GB",
        header: "Online Services Center",
    }
];

class AuthForm extends Component {
    state = {
        username: '',
        password: '',
        buttonClicked: false,
        usernameError: ''
    };

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        if (parsed && parsed.activate === '1') {
            this.setState({ buttonClicked: true });
            this.props.activateAccount(parsed);
        }

        window.scrollTo(0, 0);
    }

    updateUsername = event => {
        this.setState({
            username: event.target.value.toLowerCase(),
            usernameError: ''
        });
    }

    updatePassword = event => {
        this.setState({
            password: event.target.value,
            usernameError: ''
        });
    }

    signup = () => {
        const { username, password } = this.state;

        this.setState({ buttonClicked: false });
        if (username === '') {
            this.setState({ usernameError: "Please enter your email address and password to the above boxes and then press 'New Member' button to create your account"});
            return;
        } else {
            if (this.ePostaKontrol(username)) {
                if (password === '') {
                    this.setState({ usernameError: "Please enter a valid password"});
                    return;
                }
    
                this.setState({ buttonClicked: true });
                this.props.signup({ username, password });
            }
        }
    }

    login = () => {
        const { username, password } = this.state;

        this.setState({ buttonClicked: false });
        if (username === '') {
            this.setState({ usernameError: "Please enter your email address and password to the above boxes and then press 'Existing Member' button to create your account"});
            return;
        } else {
            if (this.ePostaKontrol(username)) {
                if (password === '') {
                    this.setState({ usernameError: "Please enter a valid password"});
                    return;
                }
    
                this.setState({ buttonClicked: true });
                this.props.login({ username, password });
            }
        }
    }

    forgottenPassword = () => {
        const { username } = this.state;

        this.setState({ buttonClicked: false });
        if (username === '') {
            this.setState({ usernameError: "Please enter your email address, press 'Forgot my Password' button and afterwards check your email box for further information"});
            return;
        } else {
            if (this.ePostaKontrol(username)) {    
                this.setState({ buttonClicked: true });
                this.props.forgottenPassword({ username });
            }
        }
    }

    Error() {
        if (this.state.buttonClicked) {
            if (this.props.account.status === fetchStates.error) {
                return <div className="Warning">{this.props.account.message}</div>
            } 
        }

        if (this.props.account.accountStatus === 'I0')
            return
                <div className="Warning">
                    Thank you for registering.
                    You will shortly receive a confirmation e-mail.
                    Please follow the instructions in that email to complete 
                    your registration.
                </div>

        if (this.state.usernameError !== '')
            return <div className="Warning">{this.state.usernameError}</div>
        
        return null
    }

    ePostaKontrol(ePosta) {
        let pos = ePosta.indexOf("@");

        if (pos === -1) {
            this.setState({ usernameError: "Please enter a valid email address including '@'"});
            return false;
        }

        let str = ePosta.substring(pos+1);

        pos = str.indexOf(".");
        if (pos < 0) {
            this.setState({ usernameError: "Please enter a valid email address including '.'"});
            return false;
        }

        if (pos === 0 || pos === str.length-1) {
            this.setState({ usernameError: "Please enter a valid email address"});
            return false;
        }

        return true;
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                {this.Error()}
                <div className="Page">
                    <div className="PageText">
                        Healthierge brings our expert doctors anywhere around the world to your home online.
                        We cover all the healthcare departments with well known professors and doctors 
                        in their fields. Please sign in using "Existing Member" button or sign in to 
                        Healthierge if you are already a member to get your online appointment now.
                        Talk to our doctors from your living room.
                        You can also enter your treatment requests, get 
                        consultation from our partners and upload your 
                        medical documents safely after having signed up.
                    </div>
                    <div className="PageText">
                        If you are not already a member, please register to
                        get access to Healthierge Online Services. Registration is free.
                    </div>
                    <div className="PageText KullaniciGiris">
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ "width" : '90px' }}>E-Mail</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="EMail"
                                value={this.state.username}
                                onChange={this.updateUsername}
                                placeholder='Enter e-mail address'
                            />
                        </InputGroup>
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ "width" : '90px' }}>Password</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Şifre"
                                value={this.state.password}
                                onChange={this.updatePassword}
                                placeholder='Enter password'
                                type="password"
                            />
                        </InputGroup>
                    </div>
                    {
                        this.props.account.status !== fetchStates.fetching ?
                        <div>
                            <div className="Auth">
                                <Button variant="success" onClick={this.login}>Existing Member</Button>
                                <div className="AuthText">or</div>
                                <Button variant="warning" onClick={this.signup}>New Member</Button>
                            </div>
                            <div className="Auth">
                                or
                            </div>
                            <div className="Auth">
                                <Button variant="danger" onClick={this.forgottenPassword}>Forgot my Password</Button>
                            </div> 
                        </div> : null
                    }
                </div>
                {
                    this.props.account.status === fetchStates.fetching ?
                    <div className="Warning">
                        <Spinner animation="border" variant="secondary" /> Connecting to Server
                    </div> : null
                }
            </div>
        );
    }
}

export default connect(
    ({ account }) => ({ account }),
    { signup, login, activateAccount, forgottenPassword }
)(AuthForm);