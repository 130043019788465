import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import fetchStates from '../reducers/fetchStates';
import { updateInterests, invokeLogger } from '../actions/account';
import HeaderSub from './HeaderSub';

const lList = [
    {
        id: "GB",
        header: "My Areas of Interest",
    }
];

class InterestedIn extends Component {
    state = {
        dental: this.props.account.dental || false,
        heart: this.props.account.heart || false,
        eye: this.props.account.eye || false,
        ear: this.props.account.ear || false,
        knee: this.props.account.knee || false,
        obesity: this.props.account.obesity || false,
        spine: this.props.account.spine || false,
        checkup: this.props.account.checkup || false,
        plastic: this.props.account.plastic || false,
        hair: this.props.account.hair || false,
        other: this.props.account.other || ''
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = "Interests: " + this.props.account.email;
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);
    }

    toggleItem = (event) => {
        this.setState({ [event.target.id]: !this.state[event.target.id] });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    save = () => {
        let id = this.props.account.id;
        let {
            dental,
            heart,   
            eye,   
            ear,   
            obesity,   
            spine,
            knee, 
            checkup,
            plastic,   
            hair,   
            other
        } = this.state;

        this.props.updateInterests({
            id,
            dental,
            heart,   
            eye,   
            ear,   
            obesity,   
            spine,
            knee, 
            checkup,
            plastic,   
            hair,   
            other
        });

        if (this.props.callPage)
            this.props.callPage('home');
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                {
                    this.props.account.status === fetchStates.fetching ?
                    <div className="Warning">
                        <Spinner animation="border" variant="secondary" /> {lItem.spinner}
                    </div> : null
                }
                <div className="Page PageText KullaniciGiris">
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="checkup"
                            onChange={this.toggleItem}
                            value={this.state.checkup}
                            checked={this.state.checkup}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="checkup">
                            Check-Up
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="hair"
                            onChange={this.toggleItem}
                            value={this.state.hair}
                            checked={this.state.hair}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="hair">
                            Hair Transplantation
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="plastic"
                            onChange={this.toggleItem}
                            value={this.state.plastic}
                            checked={this.state.plastic}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="plastic">
                            Plastic Surgery
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="dental"
                            onChange={this.toggleItem}
                            value={this.state.dental}
                            checked={this.state.dental}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="dental">
                            Dentistry
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="eye"
                            onChange={this.toggleItem}
                            value={this.state.eye}
                            checked={this.state.eye}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="eye">
                            Retina, Macula and Vitreous Surgery
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="obesity"
                            onChange={this.toggleItem}
                            value={this.state.obesity}
                            checked={this.state.obesity}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="obesity">
                            Obesity Surgery
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="knee"
                            onChange={this.toggleItem}
                            value={this.state.knee}
                            checked={this.state.knee}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="knee">
                            Knee Surgery
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="heart"
                            onChange={this.toggleItem}
                            value={this.state.heart}
                            checked={this.state.heart}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="heart">
                            Cardiovascular Surgery
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="spine"
                            onChange={this.toggleItem}
                            value={this.state.spine}
                            checked={this.state.spine}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="spine">
                            Spine Surgery
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ear"
                            onChange={this.toggleItem}
                            value={this.state.ear}
                            checked={this.state.ear}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="ear">
                            Otolaryngology Surgery
                        </label>
                    </div>
                    <br />
                    <InputGroup className="KullaniciGirisItem">
                        <InputGroup.Prepend>
                        <InputGroup.Text>Other</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl style={{ width: '300px' }}
                            aria-label="other"
                            value={this.state.other}
                            onChange={this.handleChange}
                            placeholder="Enter the topic you're interested in"
                            name='other'
                        />
                    </InputGroup>
                    <br />
                    {
                        this.props.account.status !== fetchStates.fetching ?
                        <Button variant="secondary" onClick={this.save}>Update Interests</Button> : null
                    }
                </div>
                {
                    this.props.account.status === fetchStates.fetching ?
                    <div className="Warning">
                        <Spinner animation="border" variant="secondary" /> {lItem.spinner}  Updating Interests
                    </div> : null
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { updateInterests, invokeLogger }
)(InterestedIn);