import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import fetchStates from '../reducers/fetchStates';
import { updateGeneralQuestions, invokeLogger } from '../actions/account';
import HeaderSub from './HeaderSub';

const lList = [
    {
        id: "GB",
        header: "My Current Medical Status",
    }
];

class GeneralQuestions extends Component {
    state = {
        dateOfBirth: this.props.account.dateOfBirth,
        weight: this.props.account.weight,
        height: this.props.account.height,
        operations: this.props.account.operations,
        diseases: this.props.account.diseases,
        allergies: this.props.account.allergies
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = "Medical Status: " + this.props.account.email;
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value });
    }

    save = () => {
        let {
            dateOfBirth,
            weight,
            height,
            operations,
            diseases,
            allergies
        } = this.state;
        let id = this.props.account.id;

        this.props.updateGeneralQuestions({
            id,
            dateOfBirth,
            weight,
            height,
            operations,
            diseases,
            allergies
        });
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                <div className="Page">
                    <div className="PageText">
                        Below qeustions need to be answered to receive 
                        essential information about your initial conditions 
                        and will be essential in any treatment that you'll 
                        ask from Healthierge.
                    </div>
                    <div className="PageText KullaniciGiris">
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '110px' }}>Date of Birth</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width : '300px' }}
                                aria-label="dateOfBirth"
                                value={this.state.dateOfBirth}
                                onChange={this.handleChange}
                                placeholder='Enter your date of birth'
                                name='dateOfBirth'
                            />
                        </InputGroup>
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '110px' }}>Weight (kg)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width : '300px' }}
                                aria-label="weight"
                                value={this.state.weight}
                                onChange={this.handleChange}
                                placeholder='Enter your weight in kgs'
                                name='weight'
                            />
                        </InputGroup>
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '110px' }}>Height (m)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width : '300px' }}
                                aria-label="height"
                                value={this.state.height}
                                onChange={this.handleChange}
                                placeholder='How tall are you?'
                                name='height'
                            />
                        </InputGroup>
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '110px' }}>Operations</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width : '300px' }}
                                as="textarea"
                                aria-label="operations"
                                value={this.state.operations}
                                onChange={this.handleChange}
                                placeholder='Enter if there are any operations that you have had'
                                name='operations'
                            />
                        </InputGroup>
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '110px' }}>Diseases</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width : '300px' }}
                                as="textarea"
                                aria-label="diseases"
                                value={this.state.diseases}
                                onChange={this.handleChange}
                                placeholder='Enter if you have any known diseases'
                                name='diseases'
                            />
                        </InputGroup>
                        <InputGroup className="KullaniciGirisItem">
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '110px' }}>Allergies</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl style={{ width : '300px' }}
                                as="textarea"
                                aria-label="allergies"
                                value={this.state.allergies}
                                onChange={this.handleChange}
                                placeholder='Enter if you have any allergies'
                                name='allergies'
                            />
                        </InputGroup>
                        <br />
                        {
                            this.props.account.status === fetchStates.fetching ?
                            <div className="Spinner">
                                <Spinner animation="border" variant="secondary" /> Updating Medical Status
                            </div> : 
                            <Button variant="secondary" onClick={this.save}>Update Medical Status</Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { updateGeneralQuestions, invokeLogger }
)(GeneralQuestions);