import React, { Component } from 'react';
import { connect } from 'react-redux';

import { invokeLogger } from '../actions/account';

import HeaderSub from './HeaderSub';

const priceUnit = "EUR";
const lList = [
    {
        id: "GB",
        header: "Plastic Surgery Price List",
        col1Header: "Operation Type",
        col2Header: `Price (${priceUnit})`,
        section: [
            {
                header: "Operations",
                cols:
                [
                    { item: "Eyelid Aesthetics (upper or lower)", price: "1.500" },
                    { item: "Eyelid Aesthetics (upper and lower)", price: "2.000" },
                    { item: "Lipofilling (fat injection: face-small area)", price: "1.500" },
                    { item: "Chin tip enlargement (with prothesis)", price: "1.800" },
                    { item: "Face Lift", price: "4.500" },
                    { item: "Brow lift", price: "3.000" },
                    { item: "Forehead Lift", price: "5.100" },
                    { item: "Rhinoplasty (Nose Aesthetics)", price: "2.500" },
                    { item: "Rinoplasti (Complicated-Septoplasty-Concaplasts)", price: "3.000" },
                    { item: "Cleft Palate", price: "3.600" },
                    { item: "Cleft lip (one-sided)", price: "3.250" },
                    { item: "Cleft lip (two-sided)", price: "3.600" },
                    { item: "Abdominoplasty (Tummy Tuck)", price: "4.200" },
                    { item: "Mini Abdominoplasty", price: "3.600" },
                    { item: "Mechanical Peeling", price: "1.500" },
                    { item: "Phenol Peeling", price: "1.500" }
                ]
            },
            {
                header: "Vaser Lipoplasty",
                cols:
                [
                    { item: "2 - 3 Zones", price: "3.600" },
                    { item: "3 - 4 Zones", price: "4.200" },
                    { item: "Inner Leg Stretching", price: "4.200" },
                    { item: "Lateral Thigh Lift (Brazilian Butt)", price: "5.400" },
                    { item: "Cheekbone Augmentation (with prothesis)", price: "2.100" }  
                ]
            },
            {
                header: "Mass Excision",
                cols:
                [
                    { item: "Small", price: "200" },
                    { item: "Medium", price: "300" },
                    { item: "Large", price: "450" },
                    { item: "Breast Augmentation (with Prothesis)", price: "4.200" },
                    { item: "Breast Reduction", price: "4.500" },
                    { item: "Mastopexy (Breast Lift)", price: "4.200" },
                    { item: "Brachioplasty (Arm Lift)", price: "4.200" },
                    { item: "Otoplasty (Prominent Ear)", price: "2.400" },
                    { item: "Botox (100 U)", price: "200" },
                    { item: "Hyaluronic Acid", price: "360" },
                    { item: "Hair Transplantation", price: "1.800" }
                ]
            }
        ],
        section1Para1: "In the below table, you can find Healthierge price list for some of our dentistry treatments. Please remember that the figures below are indicative prices and will be different for every patient. The real price can only be given to you, after you will have an online appointment with one of our doctors.",
        section1Para2: "Healthierge is in partnership with a number of hospitals and a big selection of doctors, all experts in their fields. The prices differ for hospitals and doctors.",
        section1Para3: "As your Health Concierge, Healthierge also offers accomodation, transfer and translation services as well as city tours and holiday services to all our customers. Please ask for more information, if you need assitance in any topic related to your travel needs.",
    }
];

class PlasticPriceList extends Component {
    componentDidMount() {
        const id = this.props.account.id;
        const text = "Plastic Prices: " + this.props.account.email;
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);

    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
                    lList.find((item) => { return item.id === 'GB' });

        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                <div className="Page">
                    <div className="PageText">
                        {lItem.section1Para1}
                    </div>
                    <div className="PageTable">
                    {
                        lItem.section.map((section,i) => (
                            <div key={i}>
                                <div className="PageTableRow">
                                    <div className="PageTableHeader">
                                        {section.header}
                                    </div>
                                </div>
                                <div className="PageTableRowHeader">
                                    <div className="PageTableCell">
                                        {lItem.col1Header}
                                    </div>
                                    <div className="PageTableCell">
                                        {lItem.col2Header}
                                    </div>
                                </div>
                                {
                                    section.cols.map((cols,k) => (
                                        <div className="PageTableRow" key={k}>
                                            <div className="PageTableCell">
                                                {cols.item}
                                            </div>
                                            <div className="PageTableCell">
                                                {cols.price}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                    </div>
                    <div className="PageText">
                        {lItem.section1Para2}
                    </div>
                    <div className="PageText">
                        {lItem.section1Para3}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(PlasticPriceList);