import React, { Component } from 'react';

import headerImg from '../assets/headerSub_doctors.jpg';

class HeaderSub extends Component {
    render () {
        return (
            <div className="HeaderSub">
                <img src={headerImg} width="100%" alt="Healthierge"/>
                <div className="HeaderSubText">
                    {this.props.header}
                </div>
            </div>
        )
    }
}

export default HeaderSub;