import { ACCOUNT } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_ACCOUNT = { loggedIn: false };

function verifyStatus(status) {
    return (status === 'AK' || status === 'AD' || status === 'I1' || status === 'TT');
}

const account = (state = DEFAULT_ACCOUNT, action) => {
    switch(action.type) {
        case ACCOUNT.FETCH:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.FETCH_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.FETCH_SUCCESS:
            return {
                ...state,
                id: action.account.id,
                firstName: action.account.firstName,
                lastName: action.account.lastName,
                email: action.account.email,
                gender: action.account.gender,
                phone: action.account.phone,
                dateOfBirth: action.account.dateOfBirth,
                weight: action.account.weight,   
                height: action.account.height,   
                operations: action.account.operations,   
                diseases: action.account.diseases,   
                allergies: action.account.allergies,
                dental: action.account.dental,
                heart: action.account.heart,   
                eye: action.account.eye,   
                ear: action.account.ear,   
                obesity: action.account.obesity,   
                spine: action.account.spine,
                knee: action.account.knee, 
                checkup: action.account.checkup,   
                plastic: action.account.plastic,   
                hair: action.account.hair,   
                other: action.account.other,
                startDate: action.account.startDate,
                country: action.account.country,
                preferredLanguage: action.account.preferredLanguage,
                newsletter: action.account.newsletter,
                userDisclaimer: action.account.userDisclaimer,
                accountStatus: action.account.accountStatus,
                status: fetchStates.success,
                message: action.message,
                loggedIn: verifyStatus(action.account.accountStatus)
            };
        case ACCOUNT.FETCH_LOGOUT_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                message: action.message,
                loggedIn: false
            };
        case ACCOUNT.FETCH_AUTHENTICATED_SUCCESS:
            return {
                ...state,
                id: action.account.id,
                firstName: action.account.firstName,
                lastName: action.account.lastName,
                email: action.account.email,
                gender: action.account.gender,
                phone: action.account.phone,
                dateOfBirth: action.account.dateOfBirth,
                weight: action.account.weight,   
                height: action.account.height,   
                operations: action.account.operations,   
                diseases: action.account.diseases,   
                allergies: action.account.allergies,
                dental: action.account.dental,
                heart: action.account.heart,   
                eye: action.account.eye,   
                ear: action.account.ear,   
                obesity: action.account.obesity,   
                spine: action.account.spine,
                knee: action.account.knee, 
                checkup: action.account.checkup,
                plastic: action.account.plastic,   
                hair: action.account.hair,   
                other: action.account.other,
                startDate: action.account.startDate,
                country: action.account.country,
                preferredLanguage: action.account.preferredLanguage,
                newsletter: action.account.newsletter,
                userDisclaimer: action.account.userDisclaimer,
                accountStatus: action.account.accountStatus,
                status: fetchStates.success,
                message: action.message,
                loggedIn: action.authenticated
            };
        case ACCOUNT.UPDATE:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.UPDATE_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.UPDATE_SUCCESS:
            return {
                ...state,
                firstName: action.account.firstName,
                lastName: action.account.lastName,
                gender: action.account.gender,
                phone: action.account.phone,
                country: action.account.country,
                preferredLanguage: action.account.preferredLanguage,
                newsletter: action.account.newsletter,
                userDisclaimer: action.account.userDisclaimer,
                accountStatus: action.account.accountStatus,
                status: fetchStates.success,
                message: action.message,
                loggedIn: true
            };  
        case ACCOUNT.ACTIVATE:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.ACTIVATE_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.ACTIVATE_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                message: action.message,
                activate: true
            };
        case ACCOUNT.FORGOTTENPASSWORD:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.FORGOTTENPASSWORD_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.FORGOTTENPASSWORD_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                message: action.message
            };  
        case ACCOUNT.UPDATEDISCLAIMER:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.UPDATEDISCLAIMER_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.UPDATEDISCLAIMER_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                userDisclaimer: action.userDisclaimer
            };
        case ACCOUNT.INVOKELOGGER:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.INVOKELOGGER_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.INVOKELOGGER_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                message: action.message
            };
        case ACCOUNT.UPDATEINTERESTS:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.UPDATEINTERESTS_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.UPDATEINTERESTS_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                dental: action.account.dental,
                heart: action.account.heart,   
                eye: action.account.eye,   
                ear: action.account.ear,   
                obesity: action.account.obesity,   
                spine: action.account.spine,
                knee: action.account.knee, 
                checkup: action.account.checkup,
                plastic: action.account.plastic,   
                hair: action.account.hair,   
                other: action.account.other
            };
        case ACCOUNT.UPDATEGENERALQUESTIONS:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.UPDATEGENERALQUESTIONS_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.UPDATEGENERALQUESTIONS_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                dateOfBirth: action.account.dateOfBirth,
                weight: action.account.weight,   
                height: action.account.height,   
                operations: action.account.operations,   
                diseases: action.account.diseases,   
                allergies: action.account.allergies
            };  
        default:
            return state;
    }
};

export default account;