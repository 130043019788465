import { APPOINTMENT } from '../actions/types';
import fetchStates from './fetchStates';

const appointment = (state = {}, action) => {
    switch(action.type) {
        case APPOINTMENT.REQUEST:
            return { ...state, status: fetchStates.fetching };
        case APPOINTMENT.REQUEST_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case APPOINTMENT.REQUEST_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                ...action
            };
        default:
            return state;
    }
};

export default appointment;