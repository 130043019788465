import { TREATMENT } from '../actions/types';
import fetchStates from './fetchStates';

const treatment = (state = {}, action) => {
    switch(action.type) {
        case TREATMENT.FETCH:
            return { ...state, status: fetchStates.fetching };
        case TREATMENT.FETCH_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case TREATMENT.FETCH_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                ...action
            };
        case TREATMENT.INSERT:
            return { ...state, status: fetchStates.fetching };
        case TREATMENT.INSERT_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case TREATMENT.INSERT_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                ...action
            };
        default:
            return state;
    }
};

export default treatment;