import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import queryString from 'query-string';
import Payment from './Payment';
import fetchStates from "../reducers/fetchStates";
import { sendAppointmentRequest } from "../actions/appointment";
import { fetchDoctors } from "../actions/doctors";
import { invokeLogger } from "../actions/account";
import { BACKEND } from '../secrets/config';

import HeaderSub from './HeaderSub';

const lList = [
    {
        id: "GB",
        header: "Ask for an Appointment",
    }
];

class AppointmentForm extends Component {
    state = {
        showMessage: 0,
        togglePayment: false,
        message: '',
        doctorId: "",
        doctorIndex: 0,
        calledFromBanner: false,
        doctorSelected: false
    }

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        const id = this.props.account.id;
        let dr = "";
        let text = "Appointment Form called from Banner";

        if (parsed) {
            if (parsed.key !== undefined) {
                this.setState({ doctorId: parsed.id, doctorIndex: parsed.key, doctorSelected: true });
                if (this.props.doctors.list !== undefined) {
                    dr = this.props.doctors.list[parsed.key];
                    text = `Appointment Form (${dr.title + ' ' + 
                            dr.firstName + ' ' + 
                            dr.lastName + ' ' + 
                            dr.titleFooter}): ${this.props.account.email}`;
                }
            }
            else {
                this.setState({ calledFromBanner: true });
            }
        }
        this.props.invokeLogger({ id, text });

        window.scrollTo(0, 0);

        if (this.props.doctors.list === undefined)
            this.props.fetchDoctors(id);
    }

    checkValidity() {
        if (this.props.account.firstName === '') {
            this.setState({
                message: `Your Account Settings (First Name) needs to be updated.
                            Please fill in all your personal information before
                            placing an appointment request` });
            return false;
        }
        if (this.props.account.lastName === '') {
            this.setState({
                message: `Your Account Settings (Last Name) needs to be updated.
                            Please fill in all your personal information before
                            placing an appointment request` });
            return false;
        }
        if (this.props.account.phone === '') {
            this.setState({
                message: `Your Account Settings (Phone) needs to be updated.
                            Please fill in all your personal information before
                            placing an appointment request` });
            return false;
        }
        if (this.props.account.gender === '') {
            this.setState({
                message: `Your Account Settings (Gender) needs to be updated.
                            Please fill in all your personal information before
                            placing an appointment request` });
            return false;
        }
        return true;
    }

    sendRequest = () => {
        const dr = this.props.doctors.list[this.state.doctorIndex];
        
        if (this.checkValidity()) {
            let sendData = {
                id: this.props.account.id,
                dr: dr.title + ' ' + dr.firstName + ' ' + dr.lastName + ' ' + dr.titleFooter,
                doctorId: this.state.doctorId,
                firstName: this.props.account.firstName,
                lastName: this.props.account.lastName,
                email: this.props.account.email,
                phone: this.props.account.phone,
                gender: this.props.account.gender,
                dateOfBirth: this.props.account.dateOfBirth,
                weight: this.props.account.weight,
                height: this.props.account.height,
                amount: dr.visitAmount,
                currency: dr.visitCurrency
            };
            this.props.sendAppointmentRequest(sendData);
            this.setState({ showMessage: 1 });
        } else {
            this.setState({ showMessage: 2 });
        }
    }

    showMessage() {
        switch (this.state.showMessage) {
            case 1:
                return (
                    <div className="Warning">
                        {
                            this.props.appointment.status === fetchStates.fetching ?
                            <Spinner animation="border" variant="secondary" />:
                            this.props.appointment.message
                        }
                    </div>
                );
            case 2: return <div className="Warning">{this.state.message}</div>
            default: return null;
        }
    }

    togglePayment = () => {
        this.setState({ togglePayment: !this.state.togglePayment });
    }

    handleChange = (e) => {
        let i = e.target.value;
        const dr = this.props.doctors.list[i];
        const id = this.props.account.id;

        let text = `Appointment Form (${dr.title + ' ' + 
                    dr.firstName + ' ' + 
                    dr.lastName + ' ' + 
                    dr.titleFooter}): ${this.props.account.email}`;

        this.props.invokeLogger({ id, text });

        this.setState({
            doctorId: dr.doctorId,
            doctorIndex: i,
            doctorSelected: true
        });
    }

    selectDoctors = () => {
        if (this.props.doctors.list === undefined)
            return;

        let list = [];
        let fList = [];
        let drList = this.props.doctors.list;

        drList.map((dr, i) => dr.footer === "" ? 
                list.push(
                    <option key={i} value={i}>
                        {dr.department} - {dr.title + ' ' + dr.firstName + ' ' + dr.lastName + ' ' + dr.titleFooter}
                    </option>
        ) : null );

        fList.push (
            <div key='FF' className="PageText KullaniciGiris">
                <InputGroup className="KullaniciGirisItem">
                    <InputGroup.Prepend>
                    <InputGroup.Text>Our Doctors</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        as="select"
                        value={this.state.doctorSelected ? this.state.doctorIndex : 'XX'}
                        onChange={this.handleChange}
                        placeholder='Please select a doctor'
                        name='doctor'
                    >
                        <option key='XX' value='XX'>Please select a doctor</option>
                        {list}
                    </FormControl>  
                </InputGroup>
            </div>
        );
        return fList;
    }

    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language }) || 
        lList.find((item) => { return item.id === 'GB' });

        let dr;

        if (this.props.appointment.status === fetchStates.fetching) {
            return (
                <div className="Warning">
                    <Spinner animation="border" variant="secondary" />
                </div>
            )
        }

        if (this.state.doctorSelected) {
            dr = this.props.doctors.list[this.state.doctorIndex];
        }
        
        return (
            <div>
                <HeaderSub language={this.props.language} header={lItem.header} />
                {this.showMessage()}
                <div className="Page">
                {
                    this.state.calledFromBanner ? this.selectDoctors() : null
                }
                {
                    this.state.doctorSelected ?
                        <div>
                        <div className="PageSection">Online Appoontment with {dr.title + ' ' + dr.firstName + ' ' + dr.lastName + ' ' + dr.titleFooter}</div>
                        <div className="PageText">
                            You are about to create an online appointment request. 
                            After you have placed your appoinment request, 
                            your Healthierge Customer Care team will contact you with
                            available slots for an online appointment.
                        </div>
                        <div className="PageSection">
                                <img src={BACKEND.ADDRESS + dr.picturePath } alt='Healthierge' />
                            </div>
                        <div className="PageSection">My Personal Information</div>
                        <div className="PageTable">
                            <div className="PageTableRowHeader">
                                <div className="PageTableCell">First Name</div>
                                <div className="PageTableCell">{this.props.account.firstName}</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Last Name</div>
                                <div className="PageTableCell">{this.props.account.lastName}</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">EMail</div>
                                <div className="PageTableCell">{this.props.account.email}</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Phone</div>
                                <div className="PageTableCell">{this.props.account.phone}</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Gender</div>
                                <div className="PageTableCell">{
                                    this.props.account.gender === 'M' ?
                                    'Male' :
                                    this.props.account.gender === 'F' ? 'Female' : 'Not selected' }
                                </div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Date of Birth</div>
                                <div className="PageTableCell">{this.props.account.dateOfBirth}</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Weight (kg)</div>
                                <div className="PageTableCell">{this.props.account.weight}</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Height (m)</div>
                                <div className="PageTableCell">{this.props.account.height}</div>
                            </div>
                        </div>
                        <div className="PageText">
                            Your doctor will receive the date mentioned above about you.
                            Please check that information and make sure your personal data in
                            account settings and my medical status sections are 
                            up to date, so that we will be able to contact you. Please do 
                            change your personal information if needed and come bsack to plac
                            your request.
                        </div>
                        <div className="PageText">
                                Below you can find Healthierge Account Details for 
                                money transfer. Please note that you need to inform 
                                your bank that you'll cover all the transfer expences,
                                so that we can receive below mentioned net amount.
                        </div>
                        <div className="PageText">
                            Please consider about 1-3 working days for international 
                            money transfers and remember that the online zoom meeting
                            invitation will only be sent, after your payment is seen
                            in Healthierge accounts.
                        </div>
                        <div className="PageSection">Account to use in Euro Zone</div>
                        <div className="PageTable">
                            <div className="PageTableRowHeader">
                                <div className="PageTableCell">Currency</div>
                                <div className="PageTableCell">EUR</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">IBAN</div>
                                <div className="PageTableCell">BE44 9671 8007 8745</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">BIC</div>
                                <div className="PageTableCell">TRWIBEB1XXX</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Amount</div>
                                <div className="PageTableCell">{dr.visitAmount} {dr.visitCurrency}</div>
                            </div>
                        </div>
                        <div className="PageSection">Account to use in United Kingdom</div>
                        <div className="PageTable">
                            <div className="PageTableRowHeader">
                                <div className="PageTableCell">Currency</div>
                                <div className="PageTableCell">GBP</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">IBAN</div>
                                <div className="PageTableCell">GB65 TRWI 2314 7045 6700 26</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Sort Code</div>
                                <div className="PageTableCell">23-14-70</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Account Number</div>
                                <div className="PageTableCell">45670026</div>
                            </div>
                            <div className="PageTableRow">
                                <div className="PageTableCell">Amount</div>
                                <div className="PageTableCell">{dr.visitAmount} GBP</div>
                            </div>
                        </div>
                        <div className="PageText">
                            <Button variant="secondary"
                                onClick={this.sendRequest}
                                disabled={this.state.showMessage === 1}
                            >
                                    1. Click Here to Send Appointment Request
                            </Button>   
                        </div>
                        <div className="PageText">
                            2. Check your Email Inbox
                        </div>
                        {
                            this.state.togglePayment ? 
                            <Payment
                                amount={dr.visitAmount}
                                currency={dr.visitCurrency}
                            />: null
                        }
                        <div className="PageText">
                            3. Make the Payment to one of the above accounts
                        </div>
                        <div className="PageText">
                            4. Healthierge Online Team will contact you with 
                            available slots for an online appointment
                        </div>
                    </div>
                    :
                    null
                }
                </div>
                {this.showMessage()}
            </div>
        )
    }
}

export default connect(
    ({ account, appointment, doctors }) => ({ account, appointment, doctors }),
    { sendAppointmentRequest, invokeLogger, fetchDoctors }
)(AppointmentForm);