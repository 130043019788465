import { BACKEND } from '../secrets/config';
import { ACCOUNT } from './types';

const fetchFromAccount = ({ endpoint, options, SUCCESS_TYPE }) => dispatch => {
    dispatch({ type: ACCOUNT.FETCH });

    return fetch(`${BACKEND.ADDRESS}/Account/${endpoint}`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error' ) {
                dispatch({ type: ACCOUNT.FETCH_ERROR, message: json.message });
            } else {
                dispatch({ type: SUCCESS_TYPE, ...json });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.FETCH_ERROR, message: error.message
        }));
}

export const signup = ({ username, password }) => fetchFromAccount({
    endpoint: 'Signup',
    options: {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    },
    SUCCESS_TYPE: ACCOUNT.FETCH_SUCCESS
});

export const login = ({ username, password }) => fetchFromAccount({
    endpoint: 'Signin',
    options: {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    },
    SUCCESS_TYPE: ACCOUNT.FETCH_SUCCESS
});

export const logout = (id) => fetchFromAccount({
    endpoint: 'Logout',
    options: {
        method: 'POST',
        body: JSON.stringify({ id }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    },
    SUCCESS_TYPE: ACCOUNT.FETCH_LOGOUT_SUCCESS
});

export const fetchAuthenticated = ({
    id,
    healthiergeSerialNumber,
    healthiergeDateOfFirstVisit,
    healthiergeAccountId,
    language,
    platform,
    vendor,
    appName,
    userAgent,
    product,
    productSub,
    cookie
}) => fetchFromAccount({
    endpoint: 'Session',
    options: {
        method: 'POST',
        body: JSON.stringify({
            id,
            healthiergeSerialNumber,
            healthiergeDateOfFirstVisit,
            healthiergeAccountId,
            language,
            platform,
            vendor,
            appName,
            userAgent,
            product,
            productSub,
            cookie
        }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    },
    SUCCESS_TYPE: ACCOUNT.FETCH_AUTHENTICATED_SUCCESS
});

export const updateAccount = ({
        id,
        firstName,
        lastName,
        gender,
        phone,
        country,
        preferredLanguage,
        newsletter,
        userDisclaimer,
        accountStatus,
        password
    }) => dispatch => {
    dispatch({ type: ACCOUNT.UPDATE });

    return fetch(`${BACKEND.ADDRESS}/Account/Update`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                firstName,
                lastName,
                gender,
                phone,
                country,
                preferredLanguage,
                newsletter,
                userDisclaimer,
                accountStatus,
                password
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.UPDATE_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.UPDATE_SUCCESS,
                    account: json.account,
                    message: json.message
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.UPDATE_ERROR,
            message: error.message
        }));
}

export const forgottenPassword = ({ username }) => dispatch => {
    dispatch({ type: ACCOUNT.FORGOTTENPASSWORD });

    return fetch(`${BACKEND.ADDRESS}/Account/Forgotten`,
        {
            method: 'POST',
            body: JSON.stringify({
                username
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.FORGOTTENPASSWORD_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.FORGOTTENPASSWORD_SUCCESS,
                    message: json.message
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.FORGOTTENPASSWORD_ERROR,
            message: error.message
        }));
}

export const activateAccount = (parsed) => dispatch => {
    dispatch({ type: ACCOUNT.ACTIVATE });

    const { id, activationToken } = parsed;
    return fetch(`${BACKEND.ADDRESS}/Account/Activate`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                activationToken
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.ACTIVATE_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.ACTIVATE_SUCCESS,
                    message: json.message
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.ACTIVATE_ERROR,
            message: error.message
        }));
}

export const updateDisclaimer = ({ id, userDisclaimer }) => dispatch => {
    dispatch({ type: ACCOUNT.UPDATEDISCLAIMER });

    return fetch(`${BACKEND.ADDRESS}/Account/UpdateDisclaimer`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                userDisclaimer
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.UPDATEDISCLAIMER_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.UPDATEDISCLAIMER_SUCCESS,
                    userDisclaimer: json.userDisclaimer
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.UPDATEDISCLAIMER_ERROR,
            message: error.message
        }));
}

export const updateInterests = ({
        id,
        dental,
        heart,   
        eye,   
        ear,   
        obesity,   
        spine,
        knee, 
        checkup, 
        plastic,  
        hair,   
        other
    }) => dispatch => {
    dispatch({ type: ACCOUNT.UPDATEINTERESTS });

    return fetch(`${BACKEND.ADDRESS}/Account/UpdateInterests`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                dental,
                heart,   
                eye,   
                ear,   
                obesity,   
                spine,
                knee, 
                checkup, 
                plastic,  
                hair,   
                other
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.UPDATEINTERESTS_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.UPDATEINTERESTS_SUCCESS,
                    ...json
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.UPDATEINTERESTS_ERROR,
            message: error.message
        }));
}

export const updateGeneralQuestions = ({
    id,
    dateOfBirth,
    weight,
    height,
    operations,
    diseases,
    allergies
}) => dispatch => {
dispatch({ type: ACCOUNT.UPDATEGENERALQUESTIONS });

return fetch(`${BACKEND.ADDRESS}/Account/UpdateGeneralQuestions`,
    {
        method: 'POST',
        body: JSON.stringify({
            id,
            dateOfBirth,
            weight,
            height,
            operations,
            diseases,
            allergies
        }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' 
    })
    .then(response => response.json())
    .then(json => {
        if (json.type === 'error') {
            dispatch({
                type: ACCOUNT.UPDATEGENERALQUESTIONS_ERROR,
                message: json.message
            });
        } else {
            dispatch({
                type: ACCOUNT.UPDATEGENERALQUESTIONS_SUCCESS,
                ...json
            });
        }
    })
    .catch(error => dispatch({
        type: ACCOUNT.UPDATEGENERALQUESTIONS_ERROR,
        message: error.message
    }));
}

export const invokeLogger = ({ id, text }) => dispatch => {
    dispatch({ type: ACCOUNT.INVOKELOGGER });

    return fetch(`${BACKEND.ADDRESS}/Account/Logger`,
        {
            method: 'POST',
            body: JSON.stringify({ id, text }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.INVOKELOGGER_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.INVOKELOGGER_SUCCESS,
                    message: json.message
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.INVOKELOGGER_ERROR,
            message: error.message
        }));
}